class ProgressBar {
    constructor (ProgressBarSelector = '.js--section-help-progress .js--progress-bar') {
        this.progressBar = document.querySelector(ProgressBarSelector);
        if (this.progressBar){
            this.init();
        }
    }

    init () {
        let progressBarWidth = this.progressBar.dataset.progress;
        this.progressBar.querySelector('.js--pb-bar-line').style.width = progressBarWidth+'%';
        if (this.progressBar.querySelector('.js--pb-bar-icon')){
            this.progressBar.querySelector('.js--pb-bar-icon').style.left = progressBarWidth+'%';
        }
    }
}

export default ProgressBar;