const FPWD_PAYU = {
    environment: '',
    merchantId: '',
};

class FPWDPayuCard {
    payuSdkForm = null;

    constructor (Config = {}) {
        this.config = Object.assign({}, FPWD_PAYU, Config);
        this.dispatchHandlers = {};

        this.initState();
        this.initForm();
    }

    initForm() {
        if (!!document.getElementById('payu-secure-form')) {
            const formOptions = {
                cardIcon: true,
                style: {
                    basic: {
                        fontSize: '14px'
                    }
                },
                placeholder: {
                    cardNumber: '0000 0000 0000 0000',
                    cvv: '000',
                    expiration: 'MM/YY'
                },
                lang: __jsVars.language,
            }

            this.payuSdkForm = PayU(this.config.merchantId, {
                dev: this.config.environment === 'TEST',
            })

            const secureForm = this.payuSdkForm.secureForms();

            const cardNumber = secureForm.add('number', formOptions);
            const cardDate = secureForm.add('date', formOptions);
            const cardCvv = secureForm.add('cvv', formOptions);

            cardNumber.render('#payu-card-number');
            cardDate.render('#payu-card-date');
            cardCvv.render('#payu-card-cvv');
        }
    }

    /**
     * Add payment scripts and run create token function
     * 
     * @see {@link http://developers.payu.com/pl/payu_express.html#payu_express_front_form}
     */
    getToken() {
        try {
            const frequencyInput = document.querySelector('input[name="frequency"]:checked');
            let frequency = frequencyInput ? frequencyInput.value : 'SINGLE';

            if (frequency === 'once') frequency = 'SINGLE';
            if (frequency === 'every_month' || frequency === 'every_week') frequency = 'MULTI';

            const adoptionFrequency = document.querySelector('input[name="frequency_day"]');

            if (!frequencyInput && adoptionFrequency) {
                frequency = 'MULTI';
            }

            this.payuSdkForm.tokenize(frequency).then((result) => {
                if (result.status === 'SUCCESS') {
                    document.querySelector('.js--payment-token-field').value = result.body.token;

                    this.onSuccess(result);
                } else {
                    this.onError({
                        paymentService: this,
                        status: Object.assign({}, result.status, { code: result.error.messages[0].code })
                    });
                }
            });
        } catch {
            this.onError({
                paymentService: this,
                status: Object.assign({}, 500, { code: 'PAYU_ERROR_CARD' })
            });
        }
    }

    onSuccess(response) {
        this.emit('success', { 
            paymentService: this,
            merchantId: this.config.merchantId, 
            environment: this.config.environment, 
            data: response.body
        }); 
    }

    onError(error) {
        this.emit('error', { paymentService: this, error: error });
    }
    
    /**
     * INIT STATE
     */
    initState() {
        this.state = {
            scriptsIsLoaded: false
        };
    }

    /**
     * SET STATE
     * @param {Object} newState 
     */
    setState(newState) {
        this.state = Object.assign({}, this.state, newState);
    }

    /**
     * Event listener
     * @param {String} eventName 
     * @param {Function} callback 
     */
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    /**
     * Event emiter
     * @param {String} eventName 
     * @param {Object} data 
     */
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

export default FPWDPayuCard;