import { elementRect } from "./Element";
import { getScrollPosition } from "./Scroll";

const InfiniteScrollDefault = {
    elementPosition: 'bottom', // Run event if section ['top', 'bottom'] position inside viewport
    offset: 100
};

class InfiniteScroll{
    constructor (Section, Settings = {}){
        this.section = Section;
        this.settings = Object.assign({}, InfiniteScrollDefault, Settings);
        this.params = {
            scrollPosition: 0,
            windowHeight: 0,
            elementPosition: 0
        };
        this.dispatchHandlers = {};

        if (this.section instanceof Element){
            this.events();
        }
    }

    /**
     * Events
     */
    events () {
        window.addEventListener('resize', this.checkPosition.bind(this));
        window.addEventListener('scroll', this.checkPosition.bind(this));
        this.checkPosition();
    }

    /**
     * Check position and emit event
     */
    checkPosition() {
        this.setParams();
     
        if (this.params.elementPosition >= this.params.scrollTop && this.params.elementPosition <= this.params.scrollBottom){
            this.emit('visible', { section: this.section, params: this.params })
        }
    }

    /**
     * Set params
     */
    setParams() {
        const windowScrollPosition = getScrollPosition().top;
        const rect = elementRect(this.section);

        this.params = {
            scrollTop: windowScrollPosition,
            scrollBottom: windowScrollPosition + window.innerHeight,
            elementPosition: (this.settings.elementPosition === 'bottom' ? rect.offset.bottom : rect.offset.top) - this.settings.offset
        };
    }

    /**
     * Event listener
     * @param {String} eventName 
     * @param {Function} callback 
     */
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    /**
     * Events emiter
     * @param {String} eventName 
     * @param {Object} data 
     */
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

export default InfiniteScroll;