export function AddButtonLoader(Button, ClassName = '') {
    if (Button){
        if (Button.tagName.toLowerCase() === 'button'){
            Button.disabled = true;
        }
        Button.classList.add('btn-loader-on');
        const Loader = document.createElement('span');
        Loader.className = `btn-loader ${ClassName}`;
        Loader.innerHTML = `<span class="loader-spin"></span>`;
        Button.appendChild(Loader);
    }
}

export function RemoveButtonLoader(Button) {
    if (Button){
        if (Button.tagName.toLowerCase() === 'button'){
            Button.disabled = false;
        }
        Button.classList.remove('btn-loader-on');

        let loaders = Button.querySelectorAll('.btn-loader');
        if (loaders.length > 0){
            for (let loader of loaders){
                loader.parentNode.removeChild(loader);
            }
        }
    }
}