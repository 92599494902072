import { bodyOverflow, setBodyFixed, unsetBodyFixed } from "../Helpers/Body";

const AlertPopupDefault = {
    overlaySelector: '.js--alert-overlay',
    insideContentSelector: '.js--alert-inside',
    btnShowSelector: '.js--alert-popup-show',
    btnHideSelector: '.js--alert-popup-hide',
    progressBarSelector: '.js--progress-bar'
}

class AlertPopup {
    constructor(SectionSelector = '.js--alert-popup') {
        this.section = document.querySelector(SectionSelector);
        if (this.section){
            this.overlay = document.querySelector(AlertPopupDefault.overlaySelector);
            this.insideContent = document.querySelector(AlertPopupDefault.insideContentSelector);
            this.btnShow = document.querySelectorAll(AlertPopupDefault.btnShowSelector);
            this.btnHide = this.section.querySelector(AlertPopupDefault.btnHideSelector);
            this.progressBar = this.section.querySelector(AlertPopupDefault.progressBarSelector);
            this.progressBarWidth = this.progressBar.dataset.progress;

            this.event();
        }
    }

    event() {
        this.btnHide.addEventListener('click', this.hidePopup.bind(this));
        for(let Button of this.btnShow){
            Button.addEventListener('click', this.showPopup.bind(this));
        }

        this.section.addEventListener('click', (event) => {
            if (!this.insideContent.contains(event.target)){
                this.hidePopup();
            }
        });
    }

    showPopup() {
        this.section.classList.add('show');
        this.progressBar.querySelector('.js--pb-bar-line').style.width = this.progressBarWidth+'%';
        if (this.progressBar.querySelector('.js--pb-bar-icon')){
            this.progressBar.querySelector('.js--pb-bar-icon').style.left = this.progressBarWidth+'%';
        }
        if (this.overlay){
            this.overlay.classList.add('show');
        }
        bodyOverflow(true, this.btnShow);
    }

    hidePopup() {
        this.section.classList.remove('show');
        this.progressBar.querySelector('.js--pb-bar-line').style.width = '0%';
        if (this.progressBar.querySelector('.js--pb-bar-icon')){
            this.progressBar.querySelector('.js--pb-bar-icon').style.left = '0%';
        }
        if (this.overlay){
            this.overlay.classList.remove('show');
        }
        bodyOverflow(false, this.btnShow);
    }
}

export default AlertPopup;