import './FPWDDropdown.scss';

const FPWDDropdownDefault = {
    btnSelector: '.js--dropdown-button',
    btnValueSelector: '.js--button-value',
    inputSelector: '.js--dropdown-input',
    listSelector: '.js--dropdown-list',
    itemSelector: '.js--dropdown-item',
    itemValueAttr: 'data-value',
    itemNameAttr: 'data-name'
};

const ACTIVE_DEFAULT = {
    value: null,
    name: ''
};

/**
 * Dropdown
 * @type {Class}
 */
class FPWDDropdown {
    constructor(DropdownSelector = '.js--dropdown', Settings = {}) {
        this.dropdowns = document.querySelectorAll(DropdownSelector);
        this.settings = Object.assign({}, FPWDDropdownDefault, Settings);
        this.dispatchHandlers = {};
        this.items = [];

        if (this.dropdowns.length > 0){
            this.init();
        }
    }

    init() {
        for (let Item of this.dropdowns){
            let Dropdown = new FPWDDropdownItem(Item, this.settings);
            Dropdown
                .on('open', (data) => this.emit('open', data))
                .on('close', (data) => this.emit('close', data))
                .on('select', (data) => this.emit('select', data));
            this.items.push(Dropdown);
        }
    }

    /**
     * Event listener
     * @param {String} eventName 
     * @param {Function} callback 
     */
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    /**
     * Events emiter
     * @param {String} eventName 
     * @param {Object} data 
     */
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

/**
 * Dropdown item
 * @type {Class}
 */
class FPWDDropdownItem {
    constructor(Dropdown, Settings) {
        this.dropdown = Dropdown;
        this.settings = Settings;
        this.dispatchHandlers = {};

        this.button = this.dropdown.querySelector(this.settings.btnSelector);
        this.buttonValue = this.button.querySelector(this.settings.btnValueSelector);
        this.input = this.dropdown.querySelector(this.settings.inputSelector);
        this.list = this.dropdown.querySelector(this.settings.listSelector);
        this.items = this.dropdown.querySelectorAll(this.settings.itemSelector);

        if (this.button && this.list){
            this.initState();
            this.events();
        }
    }

    /**
     * Events
     */
    events() {
        // Add event listener to button
        this.button.addEventListener('click', this.toggleList.bind(this));

        // Add event listener for list items
        for (let Item of this.items){
            Item.addEventListener('click', () => this.onSelect(Item));
        }

        // Click outside dropdown
        document.addEventListener('click', (event) => {
            if (!this.dropdown.contains(event.target) && this.state.isOpen){
                this.setState({ isOpen: false });
            }
        });
    }

    /**
     * On list item click
     * @param {HTMLElement} Item 
     */
    onSelect(Item) {
        const selected = this.getItemParams(Item);
        this.setState({
            isOpen: false,
            selected: selected
        });

        this.emit('select', { Dropdown: this.dropdown, state: this.state });
    }

    /**
     * Toggle list visible
     */
    toggleList() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    /**
     * 
     * @param {HTMLElement} Item 
     * @param {String|Number} ActiveValue 
     */
    getItemParams(Item = null, ActiveValue = null) {
        let result = Object.assign({}, ACTIVE_DEFAULT);

        if (Item === null && ActiveValue === null){
            return result;
        }

        const { itemSelector, itemValueAttr, itemNameAttr } = this.settings;
        let activeItem;

        if (Item){
            activeItem = Item;
        } else {
            activeItem = this.list.querySelector(`${itemSelector}[${itemValueAttr}="${ActiveValue}"]`);
        }

        if (activeItem){
            result.value = activeItem.getAttribute(itemValueAttr);
            result.name = activeItem.getAttribute(itemNameAttr);
        }

        return result;
    }

    /**
     * Set input value
     */
    setInputValue() {
        if (!this.input || !this.state.selected || !this.state.selected.value){
            return;
        }

        this.input.value = this.state.selected.value;
    }

    /**
     * Set button label
     */
    setButtonLabel() {
        if (!this.state.selected || !this.state.selected.name || this.state.selected.name === ''){
            return;
        }
        
        if (this.buttonValue){
            this.buttonValue.innerHTML = this.state.selected.name;
        } else {
            this.button.innerHTML = this.state.selected.name;
        }
    }

    /**
     * Set list visible
     */
    setListVisible() {
        if (this.state.isOpen){
            this.dropdown.classList.add('is-open');
            this.emit('open', { Dropdown: this.dropdown, state: this.state });
        } else {
            this.dropdown.classList.remove('is-open');
            this.emit('close', { Dropdown: this.dropdown, state: this.state });
        }
    }

    /**
     * Set active item
     */
    setActiveItem() {
        const { itemSelector, itemValueAttr } = this.settings;
        const { selected } = this.state;
        const activeItem = this.list.querySelector(`${itemSelector}[${itemValueAttr}="${selected.value}"]`);

        for (let Item of this.items){
            Item.classList.remove('selected');
        }

        if (activeItem){
            activeItem.classList.add('selected');
        }
    }

    /**
     * Init dropdown state
     */
    initState() {
        let selected = Object.assign({}, ACTIVE_DEFAULT);

        if (this.input && this.input.value !== ''){
            selected = this.getItemParams(null, this.input.value);
        }

        this.state = {
            isOpen: false,
            selected: selected,
            name: this.input ? this.input.name : null
        }
    }

    /**
     * Set dropdown state
     * @param {Object} newState 
     */
    setState(newState) {
        this.state = Object.assign({}, this.state, newState);

        this.setInputValue();
        this.setButtonLabel();
        this.setListVisible();
        this.setActiveItem();
    }

    /**
     * Event listener
     * @param {String} eventName 
     * @param {Function} callback 
     */
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    /**
     * Events emiter
     * @param {String} eventName 
     * @param {Object} data 
     */
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

export default FPWDDropdown;