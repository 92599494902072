
const ENV = {
    parentSectionSelector: '.js--section-parent',
    breakpoints: {
        'xs': 0,
        'xs-max': 575,
        'sm': 576,
        'sm-max': 767,
        'md': 768,
        'md-max': 991,
        'lg': 992,
        'lg-max': 1199,
        'xl': 1200
    },
    dateFormat: 'DD/MM/YYYY',
    carrency: 'zł',
    backgroundColor: {
        success: '#8ac53e',
        error: '#ed3e6b'
    }
};

export default ENV;