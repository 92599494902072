const TopBannerDefault = {
    menuSelector: '.js-banner-menu',
    menuLabelSelector: '.js-banner-menu-label'
};

class TopBanner {
    constructor (BannerSelector = '.js-top-banner') {
        this.banner = document.querySelector(BannerSelector);

        if (this.banner){
            this.init();
        }
    }

    init () {
        this.menu = this.banner.querySelector(TopBannerDefault.menuSelector);

        if (this.menu){
            this.menuLabel = this.banner.querySelector(TopBannerDefault.menuLabelSelector);

            if (this.menuLabel){
                this.menuLabel.addEventListener('click', this.toggleMenu.bind(this));

                document.addEventListener('click', (event) => {
                    var isClickInside = this.menu.contains(event.target);
                  
                    if (!isClickInside) {
                        this.closeMenu();
                    }
                });
            }
        }
    }

    toggleMenu() {
        this.menu.classList.toggle('show-menu');
    }

    closeMenu() {
        this.menu.classList.remove('show-menu');
    }
}

export default TopBanner;