/** VENDOR */
import FPWDValidate from '../../../vendor/FPWDValidate/FPWDValidate';

/** CONFIG */
import ROUTING from '../Config/Routing';

/** HELPERS */
import { ajax } from "../Helpers/Ajax";

/** COMPONENTS */
import { AddButtonLoader, RemoveButtonLoader } from '../Components/Button';
import { BoxToast, BoxToastError } from '../Components/Box';
import { SaveUserData, AutocompleteForm } from '../Helpers/SaveUserData';


class Newsletter {
    constructor (FormSelector = ".js--newsletter") {
        this.selector = FormSelector;
        this.form = document.querySelector(FormSelector);

        if (this.form){
            this.initValidation()
        }
    }

    initValidation() {
        this.validate = new FPWDValidate(this.form, {
            dynamic: true
        });
        this.validate.on('validationSuccess', ({form, values}) => {
            const Button = form.querySelector('button[type="submit"]');
            AddButtonLoader(Button);

            SaveUserData(values);
            ajax({
                url: ROUTING.newsletter,
                data: values
            }).then((response) => {
                form.reset();
                if (typeof response.message !== "undefined"){
                    BoxToast(response.message);
                }
                RemoveButtonLoader(Button);
            }).catch((error) => {
                form.reset();
                if (typeof error.message !== "undefined"){
                    BoxToastError(error.message);
                }
                RemoveButtonLoader(Button);
            });
        });
    }
}

export default Newsletter;