/** CONFIG */
import ENV from "../Config/Env";

/** HELPERS */
import { getScrollPosition } from "../Helpers/Scroll";
import { getElementParent, elementRect } from "../Helpers/Element";
import { getCookie } from "../../../vendor/FPWDCookie/FPWDCookie";

/** Default options */
const HeaderDefault = {
    headerPlaceholderSelector: '.js--header-placeholder',
    headerBoxSelector: '.js--header-box',
    contentSelector: '.js--content-wrapper',
    pageContentSelector: '.js--page-content, .page-content',
    btnHamburgetSelector: '.js-primary-menu-toggle',
    popupSelector: '.js--header-popup',
    popupAttr: 'data-popup',
    btnPopupCloseSelector: '.js--header-popup-close',
    btnShowPopupSelector: '[data-show-popup]',
    btnShowPopupAttr: 'data-show-popup',
    btnShowSearchSelector: '.js--show-search',
    btnHideSearchSelector: '.js--hide-search',
    searchWrapperSelector: '.js--header-search',
    searchInputSelector: '.js--custom-search-field'
};

export function getHeader() {
    return document.querySelector('.js--header');
}

export function getHeaderBox() {
    return document.querySelector('.js--header').querySelector(HeaderDefault.headerBoxSelector);
}

class Header {
    constructor (HeaderSelector = '.js--header') {
        this.header = document.querySelector(HeaderSelector);
        this.settings = HeaderDefault;
        this.headerBox = document.querySelector(this.settings.headerBoxSelector);
        this.headerPlaceholder = document.querySelector(this.settings.headerPlaceholderSelector);
        this.content = document.querySelector(this.settings.contentSelector);
        this.pageContent = document.querySelector(this.settings.pageContentSelector);
        this.windowWidth = window.innerWidth;

        if (this.header){
            this.headerIsStatic = this.header.classList.contains('header-static');
            this.headerAlwaysMinimalized = this.header.classList.contains('always-minimalized');
            this.init();
        }
    }

    /** INIT HEADER */
    init() {
        this.btnHabmurger = this.header.querySelector(this.settings.btnHamburgetSelector);
        this.buttonsShowPopup = this.header.querySelectorAll(this.settings.btnShowPopupSelector);
        this.popups = this.header.querySelectorAll(this.settings.popupSelector);
        this.popupsCloseButtons = this.header.querySelectorAll(this.settings.btnPopupCloseSelector);
        this.btnShowSearch = this.header.querySelector(this.settings.btnShowSearchSelector);
        this.btnHideSearch = this.header.querySelector(this.settings.btnHideSearchSelector);
        this.searchWrapper = this.header.querySelector(this.settings.searchWrapperSelector);
        this.searchInput = this.header.querySelector(this.settings.searchInputSelector);
        this.currentPopupButton = null;
        this.currentPopup = null;
        this.windowWidth = window.innerWidth;

        let firstSection = null;
        if (this.pageContent){
            firstSection = this.pageContent.getElementsByTagName('section');
        } else {
            firstSection = this.content.getElementsByTagName('section');
        }
        
        if (firstSection.length > 0){
            this.firstSection = firstSection[0];
        } else {
            this.firstSection = false;
        }

        this.menuStyle();
        this.events();
        this.topRibbonNotice();
    }

    /** HEADER EVENTS */
    events () {
        window.addEventListener('resize', () => {
            this.menuStyle();

            if (this.windowWidth !== window.innerWidth){
                this.hideAllPopups();
                this.windowWidth = window.innerWidth;
            }
        });
        window.addEventListener('scroll', this.menuStyle.bind(this));

        /** EVENT FOR HAMBURGER MENU */
        if (this.btnHabmurger){
            this.btnHabmurger.addEventListener('click', this.toggleMenu.bind(this));
        }

        /** EVENT FOR SEARCH FORM SHOW */
        if (this.btnShowSearch && this.searchWrapper){
            this.btnShowSearch.addEventListener('click', this.showSearch.bind(this));
        }

        /** EVENT FOR SEARCH FORM HIDE */
        if (this.btnHideSearch && this.searchWrapper){
            this.btnHideSearch.addEventListener('click', this.hideSearch.bind(this));
        }

        /** EVENT FOR POPUP BUTTONS SHOW */
        if (this.buttonsShowPopup.length > 0){
            for (let Button of this.buttonsShowPopup){
                Button.addEventListener('click', (event) => {
                    event.preventDefault();
                    this.currentPopupButton = Button;
                    const popupName = Button.getAttribute(this.settings.btnShowPopupAttr);
                    this.togglePopup(popupName);
                });
            }
        }

        /** EVENT FORM POPUP BUTTON CLOSE */
        if (this.popupsCloseButtons.length > 0){
            for (let Button of this.popupsCloseButtons){
                Button.addEventListener('click', (event) => {
                    event.preventDefault();
                    const popup = getElementParent(Button, this.settings.popupSelector);
                    if (popup){
                        this.hidePopup(popup.getAttribute(this.settings.popupAttr));
                    }
                });
            }
        }

        const menuItems = this.header.querySelector('.primary-menu').querySelectorAll('a');
        for (const item of menuItems){
            item.addEventListener('click', this.hideMenu.bind(this));
        }

        /** CLOSE OUTSIDE */
        document.addEventListener('click', (event) => {
            // CLOSE SEARCH ON CLICK OUTSIDE
            if (!this.searchWrapper.contains(event.target)) {
                this.hideSearch();
            }

            // CLOSE POPUPS ON CLICK OUTSIDE
            if (!this.currentPopup || !this.currentPopupButton){
                return;
            }
            if (!this.currentPopup.contains(event.target) && !this.currentPopupButton.contains(event.target)) {
                this.hideAllPopups();
            }
        });
        
    }

    /** SHOW SEARCH */
    showSearch() {
        this.searchWrapper.classList.add('show-search-form');
        this.searchWrapper.querySelector('input[type="text"]').focus();
        this.header.classList.add('header-show-search');
        document.body.classList.add('search-show');

        // Hide menu and popups
        this.hideMenu();
        this.hideAllPopups();
    }

    /** HIDE SEARCH */
    hideSearch() {
        this.searchWrapper.classList.remove('show-search-form');
        this.header.classList.remove('header-show-search');
        document.body.classList.remove('search-show');
    }

    /** HIDE ALL HEADER POPUPS */
    hideAllPopups() {
        document.body.classList.remove('body-overflow-sm');
        this.header.classList.remove('header-show-popup');
        this.currentPopup = null;
        for (let Popup of this.popups){
            Popup.classList.remove('popup-is-show');
        }
    }

    /** TOGGLE POPUP */
    togglePopup(Name) {
        const Popup = this.header.querySelector(`${this.settings.popupSelector}[${this.settings.popupAttr}="${Name}"]`);
        
        if (Popup){
            if (Popup.classList.contains('popup-is-show')){
                this.hidePopup(Name);   
            } else {
                this.showPopup(Name);
            }
        }
    }

    /** HIDE HEADER POPUP */
    hidePopup(Name) {
        const Popup = this.header.querySelector(`${this.settings.popupSelector}[${this.settings.popupAttr}="${Name}"]`);
        
        document.body.classList.remove('body-overflow-sm');
        this.header.classList.remove('header-show-popup');

        if (Popup){
            this.currentPopup = null;
            Popup.classList.remove('popup-is-show');
        }
    }

    /** SHOW HEADER POPUP */
    showPopup(Name) {
        const Popup = this.header.querySelector(`${this.settings.popupSelector}[${this.settings.popupAttr}="${Name}"]`);
        
        if (Popup){
            this.hideAllPopups();
            document.body.classList.add('body-overflow-sm');
            this.header.classList.add('header-show-popup');
            Popup.classList.add('popup-is-show');
            this.currentPopup = Popup;
            
            window.dispatchEvent(new window.CustomEvent('HeaderPopupShow', { detail: Name }));

            // Hide search and menu
            this.hideMenu();
            this.hideSearch();
        }
    }

    

    /** PRIMARY MENU STYLE ON SCROLL */
    menuStyle() {
        const scrollTop = getScrollPosition().top;
        
        if (this.firstSection && scrollTop > elementRect(this.firstSection).offset.top + this.firstSection.clientHeight){
            if (this.headerIsStatic && !this.header.classList.contains('header-fixed')){
                this.headerPlaceholder.style.height = `${this.headerBox.clientHeight}px`;
            }
            
            this.header.classList.add('header-minimalized');

            if (!this.headerAlwaysMinimalized){
                this.header.classList.add('header-dark');
                this.header.classList.add('header-fixed');
            }
        } else {
            this.headerPlaceholder.style.height = 0;
            this.header.classList.remove('header-fixed');

            if (!this.headerAlwaysMinimalized){
                this.header.classList.remove('header-dark');
                this.header.classList.remove('header-minimalized');
            }
        }

        if (window.innerWidth >= ENV.breakpoints['lg']){
            this.hideMenu();
        }
    }

    /** TOGGLE PRIMARY MENU */
    toggleMenu() {
        if (this.btnHabmurger.classList.contains('open')){
            this.hideMenu();
        } else {
            this.showMenu();
        }
    }

    /** SHOW PRIMARY MENU */
    showMenu() {
        this.btnHabmurger.classList.add('open');
        this.header.classList.add('header-show-menu');
        document.body.classList.add('primary-menu-show');

        // Hide search and popups
        this.hideSearch();
        this.hideAllPopups();
    }

    /** HIDE PRIMARY MENU */
    hideMenu() {
        this.btnHabmurger.classList.remove('open');
        this.header.classList.remove('header-show-menu');
        document.body.classList.remove('primary-menu-show');
    }

    /** TOP RIBBON */
    topRibbonNotice() {
        var shownRegulations = getCookie('shownTopRibbon');
        var regulationsNotice =  document.querySelector('.top-ribbon');
        var closeTopRibbonButton = document.querySelector('.close-top-ribbon');
        if( !shownRegulations && regulationsNotice ){
            regulationsNotice.classList.remove('hidden');
            var regulationsNoticeHeight = regulationsNotice.offsetHeight+1;
            this.header.style.top = regulationsNoticeHeight+'px';
            closeTopRibbonButton.addEventListener('click', function(){
                regulationsNotice.classList.add('hidden');
                var todayDate = new Date();
                todayDate.setTime(todayDate.getTime() + (30*24*60*60*1000));
                var expiresTime = "expires="+ todayDate.toUTCString();
                document.cookie = "shownTopRibbon=1;" + expiresTime + ";path=/";
                this.header.style.top = '0px';
            });
        }
    };
}

export default Header;