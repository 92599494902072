import SliderBox from "../Components/SliderBox";

import ENV from "../Config/Env";

class HeartsSlider {
    constructor (SectionSelector = '.js--hearts-section') {
        this.section = document.querySelector(SectionSelector);
        this.currentView = this.checkView();
        this.swiper = null;

        if (this.section){
            this.init();
        }
    }

    init () {
        this.initSwiper();
        window.addEventListener('resize', this.initSwiper.bind(this));
    }

    checkView() {
        const width = window.innerWidth;

        if (width <= ENV.breakpoints['sm-max']){
            return 'mobile';
        } else {
            return 'desktop';
        }
    }

    getOptions() {
        switch(this.currentView){
            case 'mobile':
                return {
                    loop: true,
                    effect: 'coverflow',
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    speed: 500,
                    coverflowEffect: {
                        rotate: 0,
                        stretch: 150,
                        depth: 500,
                        modifier: 1,
                        slideShadows : false,
                    },
                    breakpoints: {
                        450: {
                            coverflowEffect: {
                                stretch: 150
                            },
                        }
                    }
                };
                break;
            case 'desktop':
                return {
                    slidesPerView: 4,
                    loop: false,
                    spaceBetween: 0,
                    breakpoints: {
                        [ENV.breakpoints['md-max']]: {
                            slidesPerView: 3
                        },
                        850: {
                            slidesPerView: 2
                        }
                    }
                };
                break;
            default:
                return {};
        }
    }

    initSwiper() {
        const view = this.checkView();

        if (this.swiper === null){
            this.currentView = view;
            this.swiper = new SliderBox('.js--swiper-hearts', this.getOptions());
        } else if (this.currentView !== view && this.swiper !== null) {
            this.currentView = view;
            for (let item of this.swiper.sliders){
                item.slider.destroy(true, true);
                item.element.querySelector('.swiper-wrapper').innerHTML = item.sliderItems;
            }
            this.swiper = null;
            this.swiper = new SliderBox('.js--swiper-hearts', this.getOptions());
        }
    }
}

export default HeartsSlider;