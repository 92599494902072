import Fingerprint2 from "fingerprintjs2";

import FPWDPayuCard from "./Types/FPWDPayuCard";
import FPWDGooglePay from "./Types/FPWDGooglePay";
import FPWDApplePay from "./Types/FPWDApplePay";

import './FPWDPayment.scss';

const ALLOWED_TYPES = ["card", "googlepay", "applepay"];

const FPWD_PAYMENT = {
    formSelector: '.js--payment-form',
    amountFieldSelector: '.js--payment-amount-field',
    tokenFieldSelector: '.js--payment-token-field',
    fingerPrintFieldSelector: '.js--payment-finger-print-field',
};

class FPWDPayment {
    constructor (TYPES = ["card", "googlepay", "applepay"], Settings = {}, Config = {}) {
        this.paymentTypes = TYPES;
        this.currentPaymentType = null;
        this.settings = Object.assign({}, FPWD_PAYMENT, Settings);
        this.config = Config;
        this.payments = {};
        this.dispatchHandlers = {};

        this.form = document.querySelector(this.settings.formSelector);
        if (this.form){
            this.tokenField = this.form.querySelector(this.settings.tokenFieldSelector);
            this.fingerPrintField = this.form.querySelector(this.settings.fingerPrintFieldSelector);
            this.amountField = this.form.querySelector(this.settings.amountFieldSelector);
        }

        this.generateFingerprint();
        this.initPayments();
    }

    /**
     * Init payments
     */
    initPayments() {
        for (let paymentType of this.paymentTypes){
            let Payment = null;
          
            switch (paymentType) {
                case "card": 
                    if (typeof this.config.card !== "undefined"){
                        Payment = new FPWDPayuCard(this.config.card);
                    }
                    break;
                case "googlepay":
                    if (this.checkAmountField() && typeof this.config.googlepay !== "undefined"){
                        Payment = new FPWDGooglePay(this.config.googlepay);
                    }
                    break;
                case "applepay":
                    if (this.checkAmountField() && typeof this.config.applepay !== "undefined"){
                        Payment = new FPWDApplePay(this.config.applepay);
                    }
                    break;
                default:
                    console.warn(`Payment type "${paymentType}" is not allowed, please use one of this types: ${ALLOWED_TYPES.join(', ')}`);
            }

            if (Payment){
                Payment.on('success', (data) => {
                    this.onSuccess(data);    
                }).on('error', (error) => {
                    this.onError(error); 
                });

                this.payments[paymentType] = Payment;
            }
        }
    }

    /** 
     * GET PAYMENT TOKEN 
     */
    getToken(paymentType = null) {
        if (paymentType === null){
            console.warn('Payment is not declared, please use "getToken(PaymentType)"');
        }

        if (!ALLOWED_TYPES.includes(paymentType)){
            console.warn(`Payment type "${paymentType}" is not allowed, please use one of this types: ${ALLOWED_TYPES.join(', ')}`);
            return false;
        }

        this.currentPaymentType = paymentType;

        let amount = this.getAmount();

        if (amount){
            this.payments[paymentType].getToken(amount);
        }
    }

    setPaymentStatus(isSuccess) {
        if (this.currentPaymentType === null){
            console.warn('Payment is not declared, please use "getToken(PaymentType)"');
        }

        if (!ALLOWED_TYPES.includes(this.currentPaymentType)){
            console.warn(`Payment type "${this.currentPaymentType}" is not allowed, please use one of this types: ${ALLOWED_TYPES.join(', ')}`);
            return false;
        }

        if (typeof this.payments[this.currentPaymentType].setPaymentStatus !== "undefined"){
            this.payments[this.currentPaymentType].setPaymentStatus(isSuccess);
        }
    }

    /**
     * Generate Fingerprint to hidden input
     */
    generateFingerprint(){
        if (this.fingerPrintField){
            Fingerprint2.getV18({
                excludeJsFonts: true,
                excludeAdBlock: true,
                excludeFlashFonts: true,
                excludeWebGL: true
            }, (result) => {
                this.fingerPrintField.value = result;
            })
        }
    }

    /**
     * Get amount from input
     */
    getAmount() {
        if (!this.checkAmountField()){
            return false;
        }

        let amount = parseFloat(this.amountField.value);

        if (amount > 0){
            return amount;
        } else {
            let error = {
                code: "ERROR_AMOUNT_VALUE",
                message: `Value "${amount}" is not valid`
            };

            this.onError({ error: error })
            console.error(error);

            return false;
        }
    }

    /**
     * Check if amount field is defined
     */
    checkAmountField() {
        if (this.amountField){
            return true;
        } else {
            let error = {
                code: "AMOUNT_FIELD_NOT_FOUND",
                message: `Amount field "${this.settings.amountFieldSelector}" is not fount in "${this.settings.formSelector}" form`
            };
   
            this.onError({ error: error })
            console.error(error);

            return false;
        }
    }

    /**
     * On success
     * @param {Object} params 
     */
    onSuccess({ data, status }) {
        if (this.tokenField instanceof Element && this.tokenField.tagName.toLowerCase() === 'input'){
            this.tokenField.value = data.token;
        }
        this.emit('success', { data, status }); 
    }

    /**
     * On error
     * @param {Object} error 
     */
    onError(error) {
        this.emit('error', error); 
    }

    /**
     * Event listener
     * @param {String} eventName 
     * @param {Function} callback 
     */
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    /**
     * Event emiter
     * @param {String} eventName 
     * @param {Object} data 
     */
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

export default FPWDPayment;