class FPWDScriptLoader {
    constructor () {
        this.body = document.getElementsByTagName('body')[0];
        this.dispatchHandlers = {};
    }

    /** SET LOAD SCRIPTS */
    load(Scripts = []) {
        this.scripts = Scripts;
        this.initState();
        this.appendScript();
    }

    /** APPEND SCRIPT */
    appendScript() {
        const script = document.createElement('script');
        script.type = "text/javascript";
        script.src = this.scripts[this.state.loadCount];
        script.async = true;
        script.addEventListener('load', this.onScriptLoaded.bind(this), false);
        this.body.appendChild(script);
    }

    /** ON SCRIPT LOAD */
    onScriptLoaded () {
        this.setState({
            loadCount: this.state.loadCount + 1
        });

        if (this.state.loadCount < this.state.totalRequired){
            this.appendScript();
        }

        if (this.state.loadCount === this.state.totalRequired) {
            this.emit('load', { state: this.state });
        }
    }

    /** INIT STATE */
    initState() {
        this.state = {
            loadCount: 0,
            totalRequired: this.scripts.length
        };
    }

    /** SET STATE */
    setState (newState) {
        this.state = Object.assign({}, this.state, newState);
    }

    // Listener
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    // Emiter
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

export default FPWDScriptLoader;