import ROUTING from "../Config/Routing";

export function GetRouting(PathName = '', Params = {}) {
    if (PathName in ROUTING){
        let Path = ROUTING[PathName];

        for (let Param in Params){
            Path = Path.replace(new RegExp(`{${Param}}`, 'g'), Params[Param]);
        }

        return Path;
    }

    return null;
}