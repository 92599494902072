import FPWDModal from "../../../vendor/FPWDModal/FPWDModal";
import { AddButtonLoader, RemoveButtonLoader } from "./Button";
import { ajax } from "../Helpers/Ajax";
import { GetRouting } from "../Helpers/GetRouting";
import { BoxError } from "./Box";
import InputCount from "./InputCount";
import Video from "./Video";
import { getElementParent } from "../Helpers/Element";

const GetDescriptionDefault = {
    itemSelector: '.js--get-description-item',
    productItemSelector: '.js--product-item',
    inputCountSelector: '.js--input-count',
    idAttr: 'data-id',
    typeAttr: 'data-type'
};

class GetDescription {
    constructor (ButtonSelector = '.js--get-description') {
        this.buttonSelector = ButtonSelector;
        if (document.querySelectorAll(ButtonSelector).length > 0){
            this.events();
        }
    }

    events() {
        this.descModal = new FPWDModal();
        document.body.addEventListener('click', (event) => {
            let element = event.target;
            let finded = false;

            while (element !== null && !finded) {
                if(element.matches(this.buttonSelector)){
                    finded = true;
                    this.getDescription(element);
                } else if(element.matches(GetDescriptionDefault.itemSelector)){
                    finded = true;
                    let button = element.querySelector(this.buttonSelector);
                    if (button){
                       this.getDescription(button);
                    }
                }
                element = element.parentElement;
            }
        }, false);
    }

    /** GET PERSON DESCRIPTION */
    getDescription(Button) {
        this.type = Button.getAttribute(GetDescriptionDefault.typeAttr);
        const ID = Button.getAttribute(GetDescriptionDefault.idAttr);
        const params = this.getParams();
        const urlParams = { 
            ID: ID
        };
        
        if (this.type === 'product'){
            const productItem = getElementParent(Button, GetDescriptionDefault.productItemSelector);
            if (productItem && productItem.querySelector(GetDescriptionDefault.inputCountSelector)) {
                urlParams.quantity = productItem.querySelector(GetDescriptionDefault.inputCountSelector).value;
            } else {
                urlParams.quantity = 1
            }
        }

        if (params !== null){
            AddButtonLoader(Button, params.loader);

            ajax({
                url: GetRouting(params.routing, urlParams),
                method: 'GET'
            }).then((response) => {
                RemoveButtonLoader(Button);
                this.onDescLoad(response);
            }).catch((error) => {
                RemoveButtonLoader(Button);
                this.onDescLoadError(error);
            });
        }
    }

    getParams () {
        switch (this.type) {
            case 'team':
                return {
                    routing: 'get_team_description',
                    loader: ''
                };
            case 'product':
                return {
                    routing: 'get_product_description',
                    loader: 'img-loader'
                };
            case 'loyalty_product':
                return {
                    routing: 'get_loyalty_product_description',
                    loader: 'img-loader'
                };
            default: 
                return null;
        }
    }

    /** ON DESCRIPTION LOAD SUCCESS */
    onDescLoad(response) {

        if (typeof response.html === "undefined" || response.html === null || response.html === ''){
            this.onDescLoadError(response);
            return false;
        }

        this.descModal.setOptions({ 
            class: `modal-detail-${this.type}`,
            body: response.html
        });
        this.descModal.createModal();
        this.descModal.on('show', () => {
            window.performanceLoad.researchItems();
            window.performanceLoad.runProcessing();

            if (this.type === 'product'){
                this.inputCount = new InputCount();
                this.video = new Video();
            }
        });
    }

    /** ON DESCRIPTION LOAD ERROR */
    onDescLoadError(error) {
        this.descModal.setOptions({ body: error.message ? BoxError(error.message) : BoxError(__jsVars.l10n.errors.getContent) });
        this.descModal.createModal();
    }
}
export default GetDescription;