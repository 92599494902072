import { getElementParent } from "../Helpers/Element";

const CheckboxGroupDefault = {
    checkAllSelector: '.js--check-all',
    checkboxItemSelector: '.js--checkbox-item'
};

class CheckboxGroup {
    constructor (WrapperSelector = '.js--checkbox-group') {
        this.wrappers = document.querySelectorAll(WrapperSelector);
        this.items = [];

        if (this.wrappers.length > 0) {
            for (let wrapper of this.wrappers) {
                this.items.push(new CheckboxGroupItem(wrapper));
            }
        }
    }
}

class CheckboxGroupItem {
    constructor (Wrapper) {
        this.wrapper = Wrapper;
        this.checkAll = this.wrapper.querySelector(CheckboxGroupDefault.checkAllSelector);
        this.checkboxItems = this.wrapper.querySelectorAll(CheckboxGroupDefault.checkboxItemSelector);

        if (this.checkAll && this.checkboxItems.length > 0) {
            this.events();
        }
    }

    events () {
        window.addEventListener('updateApprovals', this.setCheckAllInput.bind(this));
        this.checkAll.addEventListener('change', this.setCheckStatusAll.bind(this));
        for (let input of this.checkboxItems) {
            input.addEventListener('change', this.setCheckAllInput.bind(this));
        }
    }

    setCheckAllInput () {
        let countInputs = 0;
        let countCheckedInputs = 0;
        for (let input of this.checkboxItems) {
            if (!input.disabled) {
                countInputs++;
                
                if (input.checked) {
                    countCheckedInputs++;
                }
            }
        }

        this.unsetCheckedPartly();
        if (countCheckedInputs === 0) {
            this.checkAll.checked = false;
        } else if (countInputs === countCheckedInputs) {
            this.checkAll.checked = true;
        } else {
            this.checkAll.checked = false;
            this.setCheckedPartly();
        }
    }

    setCheckStatusAll () {
        this.unsetCheckedPartly();
        for (let input of this.checkboxItems) {
            if (!input.disabled) {
                input.checked = this.checkAll.checked;
                input.dispatchEvent(new window.CustomEvent('checkedAllChange'));
            }
        }
    }

    setCheckedPartly () {
        getElementParent(this.checkAll, '.form-checkbox').classList.add('checked-partly');
    }

    unsetCheckedPartly () {
        getElementParent(this.checkAll, '.form-checkbox').classList.remove('checked-partly');
    }
}

export default CheckboxGroup;