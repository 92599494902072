import ENV from "./Env";
import dayjs from 'dayjs';

const DATEPICKER = {
    position: 'br',
    showAllDates: true,
    customDays: __jsVars.l10n.datepicker.days,
    customMonths: __jsVars.l10n.datepicker.months,
    overlayButton: __jsVars.l10n.datepicker.button,
    overlayPlaceholder: __jsVars.l10n.datepicker.placeholder,
    formatter: (input, date) => {
        input.value = dayjs(date).format(ENV.dateFormat);
    }
};


export default DATEPICKER;