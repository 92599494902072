import { getElementParent, triggerEvent } from "../Helpers/Element";

const FilterDefault = {
    inputSelector: '.js--filter-input',
    inputButtonSelector: '.js--filter-button',
    buttonSelector: '.js--filter-button',
    labelSelector: '.js--filter-label',
    inputLabelAttr: 'data-label',
    typeAttr: 'data-type',
    showClassName: 'show-list',
};

class Filter {
    constructor (ElementSelector = '.js--filter-wrap', Parent = document, Settings = {}){
        this.elements = Parent.querySelectorAll(ElementSelector);
        this.filters = [];

        if (this.elements.length > 0){
            for (let Element of this.elements){
                this.filters.push(new FilterItem(Element, Object.assign({}, FilterDefault, Settings)));
            }
        }
    }

    getFilters() {
        return this.filters;
    }

    getFilter(index) {
        return this.filters[index];
    }
}

class FilterItem {
    constructor (Element, Settings){
        this.element = Element;
        this.settings = Settings;

        if (this.element){
            this.filtersInputs = this.element.querySelectorAll(this.settings.inputSelector);
            this.type = this.element.getAttribute(this.settings.typeAttr);
            this.name = this.filtersInputs[0].getAttribute('name');
            this.activeElement = this.element.querySelector(`${this.settings.inputSelector}:checked`);
            this.button = this.element.querySelector(this.settings.buttonSelector);
            this.dispatchHandlers = {};
            this.events();

            if (this.activeElement){
                this.setCurrent();
            }
        }
    }

    // Events
    events () {
        for (let input of this.filtersInputs){
            if (this.type !== "sorting" && this.type !== "filters-group") {
                input.addEventListener('click', () => {
                    if (input.classList.contains('is-active')) {
                        input.classList.remove('is-active');
                        input.checked = false;
                        this.activeElement = null;
                        this.current = {
                            value: '',
                            label: __jsVars.l10n.buttons.select
                        }
                        this.setButtonLabel();
                        this.closeList();
                        this.emit('select', this.current);
                    }
                });
            }

            input.addEventListener('change', () => {
                this.activeElement = event.target;
                this.setCurrent();
                this.closeList();
                this.setActiveClass();
                this.emit('select', this.current);
            });
        }

        if (this.button){
            this.button.addEventListener('click', () => {
                if (this.element.classList.contains(this.settings.showClassName)){
                    this.closeList();
                } else {
                    this.showList();
                }
            });
        }

        document.addEventListener('click', (event) => {
            var isClickInside = this.element.contains(event.target);
          
            if (!isClickInside) {
                this.closeList();
            }
        });
    }

    setActiveClass() {
        for (let input of this.filtersInputs) {
            if (input.value === this.current.value) {
                input.classList.add('is-active');
            } else {
                input.classList.remove('is-active');
            }
        }
    }

    setActive (value) {
        const active = this.element.querySelector(`${this.settings.inputSelector}[value="${value}"]`);

        if (active) {
            active.checked = true;
            triggerEvent(active, 'change');
        }
    }

    setCurrent() {
        let label = this.activeElement.getAttribute(this.settings.inputLabelAttr);

        this.current = {
            value: this.activeElement.value,
            label: label
        }

        this.setButtonLabel();
    }

    setButtonLabel() {
        if (this.current.label){
            this.button.querySelector('span').innerText = this.current.label;
        }
    }

    // Close list
    closeList ()  {
        this.element.classList.remove(this.settings.showClassName);
        this.emit('close');
    }

    // Show list
    showList () {
        this.element.classList.add(this.settings.showClassName);
        this.emit('show');
    }

    // Listener
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    // Emiter
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

export default Filter;