import { ajax } from "../Helpers/Ajax";

const StatusSectionDefault = {
    purchaseEventDataAttr: 'data-gaevent_purchase_json',
    statusAttr: 'data-status',
    gaSendAttr: 'data-gasend',
    statusDone: 'completed',
    checkStatusTimeout: 5000
};

class StatusSection {
    constructor (SectionSelector = '.js--status-section') {
        this.sectionSelector = SectionSelector;
        this.section = document.querySelector(SectionSelector);
        
        if (this.section){
            this.init();
        }
    }
    
    init() {
        this.prevStatus = this.section.getAttribute(StatusSectionDefault.statusAttr);
        this.responseResult = document.createElement('div');
        this.url = location.href;
        this.checkStatus();
        this.statusEvents();
    }

    checkStatus() {
        ajax({
            method: 'GET',
            url: this.url
        }).then((response) => {
            this.responseResult.innerHTML = response;
            const section = this.responseResult.querySelector(this.sectionSelector);
            if (section && section.hasAttribute(StatusSectionDefault.statusAttr)){
                const status = section.getAttribute(StatusSectionDefault.statusAttr);
                let gaEventData = section.getAttribute(StatusSectionDefault.purchaseEventDataAttr);
                gaEventData = gaEventData.replace(/'/g, '"');
                gaEventData = JSON.parse(gaEventData);
                let gaSend = section.getAttribute(StatusSectionDefault.statusAttr);
                if (status === StatusSectionDefault.statusDone){
                    console.log('PJS2');
                    gtag('event', 'purchase', gaEventData);
                }

                if (this.prevStatus !== status){
                    this.section.innerHTML = section.innerHTML;
                    this.prevStatus = status;
                }

                if (status !== StatusSectionDefault.statusDone){
                    setTimeout(this.checkStatus.bind(this), StatusSectionDefault.checkStatusTimeout);
                }else{
                    //if( gaSend=='0' ){
                        console.log('PJS1');
                        gtag('event', 'purchase', gaEventData);
                    //}
                }
            }
        }).catch();
    }

    statusEvents() {
        var jsCopyTextElems = document.querySelectorAll('.js--copy-text');
        var jsCurrencyElems = document.querySelectorAll('.js--choose-currency');    
        if( jsCopyTextElems.length ){
            for (var i = 0; i < jsCopyTextElems.length; i++) {
                jsCopyTextElems[i].addEventListener( 'click', function(){
                    var elemToCopyId = this.getAttribute('data-copy-to-id');
                    var elemToCopy = document.querySelector('#'+elemToCopyId);
                    var $tempInput = document.createElement('input');
                    document.querySelector('body').append( $tempInput );
                    document.querySelector('body').append($tempInput);
                    $tempInput.value = elemToCopy.textContent;
                    $tempInput.select();
                    document.execCommand('copy');
                    $tempInput.remove();
                    elemToCopy.closest('.copy-info').querySelector('.copied-text-wrapper').classList.add('fastShown');
                    setTimeout(function(){
                        elemToCopy.closest('.copy-info').querySelector('.copied-text-wrapper').classList.remove('fastShown');
                    }, 2500);
        
                } );
            }
        }
        if( jsCurrencyElems.length ){
            for (var i = 0; i < jsCurrencyElems.length; i++) {
                jsCurrencyElems[i].addEventListener( 'click', function(){
                    var currencyLabel = this;
                    var currencyName = this.getAttribute('data-currency-name');
                    var currencyAccount = document.querySelector('.currency-account-number-'+currencyName);
                    var currencyAccountNumbers = document.querySelectorAll('.currency-account-number');
                    var currencyAmount = document.querySelector('.currency-amount-'+currencyName);
                    var currencyAmounts = document.querySelectorAll('.currency-amount');
                    var currencyLabels = document.querySelectorAll('.currency-label');
                    for (var i = 0; i < currencyAccountNumbers.length; i++) {
                        currencyAccountNumbers[i].classList.remove('currency-account-number-active');
                        currencyLabels[i].classList.remove('currency-label-active');
                        currencyAmounts[i].classList.remove('currency-amount-active');
                    }
                    currencyLabel.classList.add('currency-label-active');
                    currencyAccount.classList.add('currency-account-number-active');
                    currencyAmount.classList.add('currency-amount-active');
                } );
            }

        }
    }
    
}

export default StatusSection;