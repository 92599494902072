import { getElementParent } from "../Helpers/Element";
import { scrollPage } from "../Helpers/Scroll";
import { getHeaderBox, getHeader } from "../Common/Header";

export function ButtonScrollDown (ButtonSelector = '.js--scroll-down-button', SectionSelector = '.js--scroll-down-section') {
    if (document.querySelectorAll(ButtonSelector).length > 0){
        const scrollDown = (Button) => {
            let section = getElementParent(Button, SectionSelector);
    
            if (section && section.nextElementSibling){
                scrollPage(section.nextElementSibling, 400, 'linear');
            }
        }
        
        document.body.addEventListener('click', (event) => {
            let element = event.target;
            let finded = false;

            while (element !== null && !finded) {
                if(element.matches(ButtonSelector)){
                    finded = true;
                    scrollDown(element);
                }
                element = element.parentElement;
            }
        }, false);
    }
}

export function ButtonScrollTo (ButtonSelector = '.js--scroll-to-button', idAttr = 'data-section-id') {
    if (document.querySelectorAll(ButtonSelector).length > 0){
        const scrollTo = (Button) => {
            if( Button.getAttribute(idAttr)=='0' ){
                scrollPage(0, 400, 'linear');
            }else{
                let section = document.getElementById(Button.getAttribute(idAttr));
        
                if (section){
                    scrollPage(section, 400, 'linear');
                }
            }
        }
        
        document.body.addEventListener('click', (event) => {
            let element = event.target;
            let finded = false;
            while (element !== null && !finded) {
                if(element.matches(ButtonSelector)){
                    event.preventDefault();
                    finded = true;
                    scrollTo(element);
                }
                element = element.parentElement;
            }
        }, false);
    }
}