import './FPWDFileUpload.scss';

if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function(s) {
            let matches = (this.document || this.ownerDocument).querySelectorAll(s),
                i = matches.length;
            while (--i >= 0 && matches.item(i) !== this) {}
            return i > -1;
        };
}

const FPWDFileUploadDefault = {
    url: '/',
    required: false,
    dropzone: true,
    preview: true,
    limit: 10,
    maxSize: 5, // in MB
    types: 'image/jpeg|image/jpg',
    inputSelector: '.js--file-upload-input',
    previewSelector: '.js--file-upload-preview',
    insideBoxSelector: '.js--file-upload-inside',
    errorClassName: 'js-validate-error-label',
    warningClassName: 'js-validate-warning-label',
};

export const FPWDFileUploadMessages = {
    default: __jsVars.l10n.errors.default,
    required: __jsVars.l10n.validation.required,
    duplicated: __jsVars.l10n.validation.uploaderDuplicated,
    limit: __jsVars.l10n.validation.uploaderLimit,
    type: __jsVars.l10n.validation.uploaderType,
    size: __jsVars.l10n.validation.uploaderSize,
};

class FPWDFileUpload {
    constructor (Wrapper = '.js--file-upload', Settings = {}, MESSAGES = {}) {
        this.sections;

        if (Wrapper instanceof Element){
            this.sections = [Wrapper];
        } else {
            this.sections = document.querySelectorAll(Wrapper);
        }

        this.settings = Object.assign({}, FPWDFileUploadDefault, Settings);
        this.messages = Object.assign({}, FPWDFileUploadMessages, MESSAGES);;
        this.items = [];
        this.dispatchHandlers = {};

        if (this.sections.length > 0){
            this.init();
        }
    }

    init () {
        for (let Section of this.sections){
            let upload = new FPWDFileUploadItem(Section, this.settings, this.messages);
            upload
                .on('validationError', (data) => this.emit('validationError', data))
                .on('appendFile', (data) => this.emit('appendFile', data))
                .on('removeFile', (data) => this.emit('removeFile', data))
                .on('appendAll', (data) => this.emit('appendAll', data))
                .on('sendSuccess', (data) => this.emit('sendSuccess', data))
                .on('sendError', (data) => this.emit('sendError', data));

            this.items.push(upload);
        }
    }

    /** GET ALL FILES UPLOADERS */
    getItems(){
        return this.items;
    }

    /** GET UPLOADER ITEM */
    getItem(index){
        return this.items[index];
    }

    // Listener
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    // Emiter
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

class FPWDFileUploadItem{
    constructor(Section, Settings, Messages){
        this.section = Section;
        this.settings = Settings;
        this.messages = Messages;
        this.dispatchHandlers = {};

        this.initState();
        this.init();
    }

    init() {
        this.input = this.section.querySelector(this.settings.inputSelector);
        this.preview = this.section.querySelector(this.settings.previewSelector);
        
        this.initState();
        this.events();
    }

    /** INIT UPLOADER STATE */
    initState() {
        this.state = {
            formData: {},
            files: {}
        };
    }

    /** EVENTS */
    events() {
        // Input file change
        if (this.input){
            this.input.addEventListener('change', this.onSelectFiles.bind(this), false);
        }

        // Dropzone events
        if (this.settings.dropzone){
            this.section.addEventListener('dragenter', this.onDragEnter.bind(this), false);
            this.section.addEventListener('dragover', this.onDragEnter.bind(this), false);
            this.section.addEventListener('dragleave', this.onDragLeave.bind(this), false);
            this.section.addEventListener('drop', this.onDropFiles.bind(this), false);
        }

        // Preview events
        if (this.settings.preview && this.preview){
            // On click preview remove button
            this.preview.addEventListener('click', (event) => {
                let element = event.target;
                let finded = false;

                while (element !== null && !finded) {
                    if(element.matches('.js--preview-remove')){
                        finded = true;
                        this.removeFileFromState(element.getAttribute('data-id'));
                    }
                    element = element.parentElement;
                }
            }, false);
        }
    }

    /** EVENT ON DRAG&DROP FILES ON DROP ZONE */
    onDropFiles(event) {
        this.preventDefault(event);
        this.onDragLeave(event);
        
        if (event.dataTransfer.files.length > 0){
            this.addFilesToState(event.dataTransfer.files);
        }
    }

    /** EVENT ON FILES SELECTED FROM INPUT */
    onSelectFiles(event){
        if (event.target.files.length > 0){
            this.addFilesToState(event.target.files);
            event.target.value = '';
        }
    }

    /** CHECK IS VALID */
    checkValid() {
        if (!this.settings.required){
            this.removeErrors();
            return true; 
        }

        if (Object.keys(this.state.files).length > 0){
            this.removeErrors();
            return true;
        } else {
            this.addError('required');
            return false;
        }
    }

    /** ADD FILE TO STATE */
    addFilesToState(Files){
        for (let File of Files){
            let isValid = true;

            // Check limit
            if (!this.validateLimit()){
                this.emit('validationError', { Uploader: this, Type: 'limit', File: File });
                isValid = false;
                break;
            }

            // Check duplicated
            if (!this.validateDuplicated(File)){
                this.emit('validationError', { Uploader: this, Type: 'duplicated', File: File });
                isValid = false;
            }
            
            // Check type
            if (!this.validateType(File)){
                this.emit('validationError', { Uploader: this, Type: 'type', File: File });
                isValid = false;
            }
            
            // Check size
            if (!this.validateSize(File)){
                this.emit('validationError', { Uploader: this, Type: 'size', File: File });
                isValid = false;
            }
            
            // if valid -> Add file to state
            if (isValid){
                let ID = `file_${Object.keys(this.state.files).length}`;
                this.state.files[ID] = File;

                // if preview -> create preview item
                if (this.settings.preview){
                    this.addPreview(ID);
                }
                this.checkValid();
                this.emit('appendFile', { Uploader: this, File: File });
            }
        }

        this.emit('appendAll', { Uploader: this, Files: this.state.files });
    }

    /** REMOVE FILE FROM STATE */
    removeFileFromState(ID) {
        if (ID in this.state.files){
            delete this.state.files[ID];
            this.removePreview(ID);
            this.emit('removeFile', { Uploader: this, Files: this.state.files });
        }
    }

    /** ADD FILE TO PREVIEW */
    addPreview(ID) {
        let File = this.state.files[ID];
        let Rader = new FileReader();

        Rader.addEventListener('load', (event) => {
            let previewItem = document.createElement('div');
            previewItem.className = 'preview-item';
            previewItem.setAttribute('data-id', ID);

            previewItem.innerHTML = `
                <div class="preview-inside">
                    <button type="button" class="preview-remove js--preview-remove" data-id="${ID}"><i class="icon-close"></i></button>
                    <div class="preview-image" style="background-image: url('${event.target.result}')"></div>
                </div>
            `;

            this.preview.appendChild(previewItem);
        });

        Rader.readAsDataURL(File);
    }

    /** REMOVE FILE FORM PREVIEW */
    removePreview(ID) {
        let previewItem = this.preview.querySelector(`.preview-item[data-id="${ID}"]`);
        if (previewItem){
            this.preview.removeChild(previewItem);
        }
    }

    /** RESET PREVIEW */
    resetPreview() {
        this.preview.innerHTML = '';
    }

    /** SET FORM DATA TO UPLOAD WITH FILES */
    setFormData(data) {
        this.state.formData = data;
    }

    /** RESET FILES UPLOADER */
    reset() {
        this.initState();
        this.resetPreview();
    }

    /** SEND FILES WITH ANOTHER DATA */
    sendFiles() {
        if (this.checkValid()){
            let UploadFormData = new FormData();

            // Add form data to XMLHttpRequest->FormData
            for (let Name in this.state.formData){
                UploadFormData.append(Name, this.state.formData[Name]);
            }

            // Add files to XMLHttpRequest->FormData
            for (let ID in this.state.files){
                UploadFormData.append(ID, this.state.files[ID]);
            }

            const xhr = new XMLHttpRequest();
            xhr.open('POST', this.settings.url, true);

            let _this = this;
            xhr.onload = function() {
                if (this.status === 200) {
                    _this.emit('sendSuccess', { Uploader: _this, response: this.response });
                } else {
                    _this.emit('sendError', { Uploader: _this, Error: this.response });
                }
            };
            xhr.send(UploadFormData);
        }
    }

    /** VALIDATE FILES LIMIT */
    validateLimit() {
        if (!this.settings.limit){
            return true;
        }

        return this.settings.limit - 1 >= Object.keys(this.state.files).length;
    }
    
    /** VALIDATE DUPLICATED */
    validateDuplicated(File) {
        for (let i in this.state.files) {
            const fileItem = this.state.files[i];
            if (fileItem.name === File.name && fileItem.size === File.size){
                return false;
            }
        }

        return true;
    }

    /** VALIDATE FILE TYPE */
    validateType(File) {
        if (!this.settings.types || this.settings.types === '*'){
            return true;
        }

        return File.type.match(this.settings.types);
    }

    /** VALIDATE FILE SIZE */
    validateSize(File) {
        if (!this.settings.maxSize){
            return true;
        }

        return this.settings.maxSize * 1024 * 1024 >= File.size;
    }

    removeErrors () {
        this.section.classList.remove(`fpwd-error-uploader`);
        let Errors = this.section.querySelectorAll(`.${this.settings.errorClassName}`);

        for (let Item of Errors){
            Item.parentNode.removeChild(Item);
        }
    }

    addError(ErrorType) {
        if (this.section.querySelectorAll(`.${this.settings.errorClassName}[data-error-type="${ErrorType}"]`).length === 0){
            let error = document.createElement('div');
            error.className = this.settings.errorClassName;
            error.setAttribute('data-error-type', ErrorType);
            error.innerHTML = this.messages[ErrorType];

            this.section.querySelector(this.settings.insideBoxSelector).appendChild(error);
        }

        this.section.classList.add(`fpwd-error-uploader`);
        this.emit('validationError', { Uploader: this, Type: ErrorType });
    }

    getErrorMessage(Type, File) {
        if (FPWDFileUploadMessages[Type]){
            let message = FPWDFileUploadMessages[Type];
            if (File && File.name){
                message = message.replace(/:fileName/g, `<b>${File.name}</b>`);
            }
            switch (Type){
                case 'limit':
                    return message.replace(/:filesLimit/g, `<b>${this.settings.limit}</b>`);
                case 'type':
                    let types = this.settings.types;
                    types = types.replace(/image\//g, '');
                    return message.replace(/:fileType/g, `<b>${types.split('|').join(', ')}</b>`);
                case 'size':
                    return message.replace(/:fileSize/g, `<b>${this.settings.maxSize}MB</b>`);
                default:
                    return message;
            }
        } else {
            return FPWDFileUploadMessages.default;
        }
    }

    /** PREVENT EVENTS ON DRAG&DROP */
    preventDefault(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    /** ADD STYLING ON DRAG ENTER */
    onDragEnter() {
        this.preventDefault(event);
        this.section.classList.add('drag-enter');
    }

    /** REMOVE STYLING ON DRAG LEAVE */
    onDragLeave() {
        this.preventDefault(event);
        this.section.classList.remove('drag-enter');
    }

    // Listener
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    // Emiter
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

export default FPWDFileUpload;