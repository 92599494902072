/** VENDOR */
import FPWDAccordion from "../../../vendor/FPWDAccordion/FPWDAccordion";

/** HELPERS */
import { ajax } from "../Helpers/Ajax";
import { BoxError, BoxLoading, BoxToastError } from "../Components/Box";
import { GetRouting } from "../Helpers/GetRouting";
import SliderBox from "../Components/SliderBox";

const RatingListDefault = {
    idAttr: 'data-id'
};

class RatingList {
    constructor (Selector = '.js--rating-list') {

        this.accordion = new FPWDAccordion(Selector, {
            dynamic: true
        });

        this.accordion.on('dynamic', ({ Accordion, Item}) => {
            this.getContent(Accordion, Item)
        });
    }

    getContent(Accordion, Item) {
        const Head = Item.querySelector(Accordion.settings.headSelector);
        const Content = Item.querySelector(Accordion.settings.contentSelector);
        const ID = parseInt(Head.getAttribute(RatingListDefault.idAttr));

        Content.innerHTML = BoxLoading(__jsVars.l10n.errors.getContent);
        Accordion.show(Item);

        ajax({
            method: 'GET',
            url: GetRouting('get_rating_content', { ID: ID }),
        }).then((response) => {
            if (typeof response.html !== "undefined"){
                Content.innerHTML = response.html;    
                Accordion.onLoad(Item);
                window.performanceLoad.researchItems();
                window.performanceLoad.runProcessing();
                this.slidersReInit = new SliderBox();
            } else {
                Content.innerHTML = BoxError(__jsVars.l10n.errors.getContent, true);
            }

            Accordion.resize();
        }).catch((error) => {
            Content.innerHTML = BoxError(__jsVars.l10n.errors.getContent, true); 
            Accordion.resize();
            BoxToastError(error.message);
        });
    }
}

export default RatingList;