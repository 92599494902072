import { getElementParent } from "../Helpers/Element";
import { ShoppingCartProductFactory } from "./ShoppingCart";
import { BoxToastError } from "./Box";
import { GetRouting } from "../Helpers/GetRouting";

const InputCountDefault = {
    inputSelector: '.js--input-count',
    buttonsMinusSelector: '.js--count-minus',
    buttonsPlusSelector: '.js--count-plus',
    submitButtonSelector: '.js--button-submit',
    isInitClassName: 'is-init',
    minValue: 0
};

class InputCount {
    constructor (WrapperSelector = '.js--product-count', Settings = {}) {
        this.settings = Object.assign({}, InputCountDefault, Settings);
        this.wrappers = document.querySelectorAll(`${WrapperSelector}:not(.${this.settings.isInitClassName})`);
        this.components = [];
        this.dispatchHandlers = {};
       
        for (let wrapper of this.wrappers){
            const Input = new InputCountItem(wrapper, this.settings);
            Input.on('changeValue', (data) => this.emit('changeValue', data))
            this.components.push(Input);
        }
    }

    // Listener
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    // Emiter
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

class InputCountItem {
    constructor (Wrapper, Settings) {
        this.wrapper = Wrapper;
        this.settings = Settings;
        this.currentValue = 1;
        this.dispatchHandlers = {};

        this.form = getElementParent(this.wrapper, 'form');
        this.input = this.wrapper.querySelector(this.settings.inputSelector);
        this.buttonMinus = this.wrapper.querySelector(this.settings.buttonsMinusSelector);
        this.buttonPlus = this.wrapper.querySelector(this.settings.buttonsPlusSelector);

        if (this.form){
            this.submit = this.form.querySelector(this.settings.submitButtonSelector);
        }

        if (this.input){
            this.inputEvents();
        }

        if (this.buttonMinus && this.buttonPlus){
            this.buttonsEvents();
        }

        this.wrapper.classList.add(this.settings.isInitClassName);
    }

    /** INPUT EVENTS */
    inputEvents () {
        this.inputMask = IMask(this.input, {
            mask: Number,
            min: this.settings.minValue
        });

        this.currentValue = parseInt(this.inputMask.value);
        this.inputMask.on("accept", () => {
            this.currentValue = parseInt(this.inputMask.value);
        });

        this.input.addEventListener('blur', (event) => {
            if (event.target.value.trim() === ''){
                this.setValue(0);
            } else {
                this.setValue(parseInt(this.inputMask.value));
            }
        });
    }

    /** BUTTONS EVENTS */
    buttonsEvents() {
        this.buttonMinus.addEventListener('click', () => {
            if (this.currentValue > this.settings.minValue){
                this.setValue(this.currentValue - 1);
            }
        });
        
        this.buttonPlus.addEventListener('click', () => {
            this.setValue(this.currentValue + 1);
        });
    }

    /** SET VALUE */
    setValue(value){
        this.inputMask.value = value.toString();
        this.currentValue = parseInt(value);

        if (this.submit){
            this.submit.disabled = false;
        }
        
        this.emit('changeValue', { InputCount: this, value: this.currentValue });
    }

    // Listener
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    // Emiter
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

export default InputCount;