/** VENDOR */
import '../../../vendor/Swiper/Swiper';

import Filter from "../Components/Filter";

import ENV from "../Config/Env";

const ShoppingTypesDefault = {
    filtersSelector: '.js--filter-wrap',
    sliderSelector: '.js--swiper-shopping-types',
    itemSelector: '.shopping-types-item',
    itemActiveClassName: 'item-active',
    typeAttr: 'data-type',
    indexAttr: 'data-index'
}

const getCurrentDevice = () => {
    const width = window.innerWidth;
    if (width <= ENV.breakpoints['sm-max']){
        return 'mobile';
    } else {
        return 'desktop';
    }
};

const getShoppingTypesSwiperSettings = () => {
    const width = window.innerWidth;

    if (width <= ENV.breakpoints['sm-max']){
        return {
            effect: 'coverflow',
            slidesPerView: 'auto',
            centeredSlides: true,
            speed: 500,
            coverflowEffect: {
                rotate: 70,
                stretch: 50,
                depth: 500,
                modifier: 1,
                slideShadows : false,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
              },
              navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
              }
        };
    } else if (width >= ENV.breakpoints['md']){
        return {
            centeredSlides: true,
            slidesPerView: 3,
            spaceBetween: 30,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                [ENV.breakpoints['md-max']]: {
                    spaceBetween: 15,
                    slidesPerView: 'auto'
                }
            }
        };
    }
};

class ShoppingTypes {
    constructor (SectionSelector = '.js--shopping-types') {
        this.sections = document.querySelectorAll(SectionSelector);
        this.settings = ShoppingTypesDefault;
        this.items = [];

        if (this.sections.length > 0){
            for (let section of this.sections) {
                this.items.push(new ShoppingTypesItem(section, this.settings));
            }
        }
    }
}

class ShoppingTypesItem {
    constructor (Section, Settings) {
        this.section = Section;
        this.settings = Settings;
        this.filters = null;
        this.swiper = null;
        this.sliderItems = null;

        this.initState();
        this.initSwiper();
        this.initFilters();
        this.setSliderItems();

        window.addEventListener('resize', this.reinitSwiper.bind(this));
    }

    initFilters() {
        this.filters = new Filter(this.settings.filtersSelector, this.section);
        for (let FilterItem of this.filters.getFilters()) {
            FilterItem.on('select', (data) => {
                if (this.state.activeFilter !== data.value){
                    this.setFilterState(data.value);
                }
            });

            this.setState({
                initialFilter: FilterItem.current.value
            });
            this.setFilterState(FilterItem.current.value);
        }
    }

    reinitSwiper() {
        const currentDevice = getCurrentDevice();

        if (this.state.device !== currentDevice) {
            if (this.swiper) {
                this.swiper.destroy(true, true);
                this.swiper = null;
                this.initSwiper();
                this.setSlider();
                this.setFilterState(this.state.initialFilter);
            }
            this.setState({
                device: currentDevice
            })
        }
    }

    initSwiper() {
        this.swiper = new Swiper(this.settings.sliderSelector, getShoppingTypesSwiperSettings());

        this.swiper.on('slideChange', () => {
            const active = this.section.querySelector(`${this.settings.itemSelector}[${this.settings.indexAttr}="${this.swiper.activeIndex}"]`);
            if (active) {
                this.setState({
                    activeFilter: active.getAttribute(this.settings.typeAttr),
                    currentSlidePosition: this.swiper.activeIndex,
                }, 'filter');
            }
        });

        this.sliderItems = this.swiper.wrapperEl.querySelectorAll(this.settings.itemSelector);
    }

    setFilterState (value = '') {
        const firstSliderOfType = this.section.querySelectorAll(`${this.settings.itemSelector}[${this.settings.typeAttr}="${value}"]`);
        let currentSlidePosition = 0;

        if (firstSliderOfType[0] && firstSliderOfType[0].hasAttribute(this.settings.indexAttr)) {
            currentSlidePosition = parseInt(firstSliderOfType[0].getAttribute(this.settings.indexAttr));
        }

        this.setState({
            activeFilter: value,
            currentSlidePosition: currentSlidePosition,
        }, 'slider');
    }

    setSlider () {
        this.swiper.slideTo(this.state.device === 'desktop' ? this.state.currentSlidePosition + 1 : this.state.currentSlidePosition);
    }

    setSliderItems () {
        if (this.sliderItems && this.sliderItems.length > 0) {
            for (let slide of this.sliderItems) {
                if (slide.hasAttribute(this.settings.typeAttr) && slide.getAttribute(this.settings.typeAttr) === this.state.activeFilter) {
                    slide.classList.remove('is-disabled');
                } else {
                    slide.classList.add('is-disabled');
                }
            }
        }
    }

    initState () {
        this.state = {
            initialFilter: null,
            device: getCurrentDevice(),
            activeFilter: null,
            currentSlidePosition: 0,
        }
    }

    setState (newState, update = '') {
        this.state = {...this.state, ...newState};


        if (update === 'slider') {
            this.setSlider();
        } else if (update === 'filter') {
            this.filters.getFilter(0).setActive(this.state.activeFilter);
        }

        this.setSliderItems();
    }
}

export default ShoppingTypes;