import { GetRouting } from "../Helpers/GetRouting";
import { ajax } from "../Helpers/Ajax";
import { BoxError, BoxLoading } from "../Components/Box";
import SliderBox from "../Components/SliderBox";

const HstoryDefault = {
    timelineSelector: '.js--history-points',
    btnActiveClass: 'button-active',
    contentSelector: '.js--history-content',
    sliderSelector: '.js--history-slider',
    sliderItemSelector: '.js--history-item',
    btnGoToYearSelectorr: '.js--go-to-year',
    btnGoToNextSelectorr: '.js--go-to-next',
    yearAttr: 'data-year',
    indexAttr: 'data-index',
    typeAttr: 'data-type'
};

class History{
    constructor (SectionSelector = '.js--history', Settings = {}) {
        this.sections = document.querySelectorAll(SectionSelector);
        this.settings = Object.assign({}, HstoryDefault, Settings);
        this.items = [];

        if (this.sections.length > 0){
            for (let Section of this.sections){
                let type = Section.getAttribute(this.settings.typeAttr);
                switch (type){
                    case 'content':
                        this.items.push(new HistoryCotnentItem(Section, this.settings));
                        break;
                    case 'slider':
                        this.items.push(new HistorySliderItem(Section, this.settings));
                        break;
                }
            }
        }
    }
}

class HistoryCotnentItem {
    constructor (Section, Settings) {
        this.section = Section;
        this.settings = Settings;

        this.init();
    }

    init () {
        this.timeline = this.section.querySelector(this.settings.timelineSelector);
        this.buttonsYear = this.timeline.querySelectorAll(this.settings.btnGoToYearSelectorr);
        this.content = this.section.querySelector(this.settings.contentSelector);

        const activeItem = this.timeline.querySelector(`${this.settings.btnGoToYearSelectorr}.${this.settings.btnActiveClass}`);
        if (activeItem && activeItem.hasAttribute(this.settings.yearAttr)){
            this.currentYear = parseInt(activeItem.getAttribute(this.settings.yearAttr));
        }

        if (this.timeline){
            this.initTimeLineSlider();
        }

        this.events();
    }

    /** EVENTS */
    events() {
        if (this.buttonsYear.length > 0){
            for (let Button of this.buttonsYear){
                Button.addEventListener('click', (event) => {
                    event.preventDefault();
                    if (Button.hasAttribute(this.settings.yearAttr)){
                        this.currentYear = parseInt(Button.getAttribute(this.settings.yearAttr));
                        this.getYear();
                        this.setButtons();
                    }
                });
            }
        }
    }

    /** INIT POINTS SLIDER */
    initTimeLineSlider() {
        this.timelineSwiper = new Swiper(this.timeline, {
            slidesPerView: 'auto',
            spaceBetween: 0,
            freeMode: true,
            navigation: {
                prevEl: this.section.querySelector('.history-prev'),
                nextEl: this.section.querySelector('.history-next'),
            }
        });
    }


    /** GO TO SLIDE OF YEAR */
    getYear(){
        this.setContent(BoxLoading(true));

        ajax({
            url: GetRouting('get_achievements', { YEAR: this.currentYear }),
            method: "GET"
        }).then((response) => {
            if (response.html){
                this.setContent(response.html);
            }
        }).catch((error) => {
            this.setContent(BoxError(__jsVars.l10n.errors.getContent, true));
        });
    }

    setContent(HTML) {
        this.content.innerHTML = HTML;

        window.performanceLoad.researchItems();
        window.performanceLoad.runProcessing();
        this.slidersReInit = new SliderBox('.js--swiper-slider-box');
    }

    /** SET CURRENT YEAR BUTTON */
    setButtons() {
        for (let Button of this.buttonsYear){
            if (Button.hasAttribute(this.settings.yearAttr) && parseInt(Button.getAttribute(this.settings.yearAttr)) === this.currentYear){
                Button.classList.add(this.settings.btnActiveClass);
            } else {
                Button.classList.remove(this.settings.btnActiveClass);
            }
        }
    }
}

class HistorySliderItem {
    constructor (Section, Settings) {
        this.section = Section;
        this.settings = Settings;

        this.init();
    }

    init () {
        this.timeline = this.section.querySelector(this.settings.timelineSelector);
        this.buttonsYear = this.timeline.querySelectorAll(this.settings.btnGoToYearSelectorr);
        this.slider = this.section.querySelector(this.settings.sliderSelector);

        if (this.timeline){
            this.initTimeLineSlider();
        }

        if (this.slider){
            this.buttonsNext = this.slider.querySelectorAll(this.settings.btnGoToNextSelectorr);
            this.initContentSlider();
        }

        this.events();
    }

    /** EVENTS */
    events() {
        if (this.buttonsYear.length > 0){
            for (let Button of this.buttonsYear){
                Button.addEventListener('click', (event) => {
                    event.preventDefault();
                    if (Button.hasAttribute(this.settings.yearAttr)){
                        let year = parseInt(Button.getAttribute(this.settings.yearAttr));
                        this.gotToYear(year);
                    }
                });
            }
        }

        if (this.slider && this.buttonsNext.length > 0){
            for (let Button of this.buttonsNext){
                Button.addEventListener('click', (event) => {
                    event.preventDefault();
                    this.gotToNext();
                });
            }
        }
    }

    /** INIT POINTS SLIDER */
    initTimeLineSlider() {
        this.timelineSwiper = new Swiper(this.timeline, {
            slidesPerView: 'auto',
            spaceBetween: 0,
            freeMode: true,
            navigation: {
                nextEl: this.section.querySelector('.history-prev'),
                prevEl: this.section.querySelector('.history-next'),
            }
        });
    }

    /** INIT CONTENT SLIDER */
    initContentSlider() {
        this.sliderSwiper = new Swiper(this.slider, {
            init: false,
            slidesPerView: 1,
            spaceBetween: 0,
            autoHeight: true
        });

        this.sliderSwiper.on('init', this.setAll.bind(this));
        this.sliderSwiper.on('slideChangeTransitionEnd', this.setAll.bind(this));

        this.sliderSwiper.init();
    }

    /** GO TO SLIDE OF YEAR */
    gotToYear(year){
        let slide = this.sliderSwiper.el.querySelector(`${this.settings.sliderItemSelector}[${this.settings.yearAttr}="${year}"]`);

        if (slide && slide.hasAttribute(this.settings.yearAttr)){
            let index = parseInt(slide.getAttribute(this.settings.indexAttr));
            this.currentYear = year;
            this.currentIndex = index;
            this.setButtons();
            this.sliderSwiper.slideTo(index);
        }
    }

    /** GO TU NEXT SLIDE ACTION */
    gotToNext(){
        if (this.currentIndex + 1 < this.itemsLength){
            this.sliderSwiper.slideTo(this.currentIndex + 1);
        }
    }

    /** SET DATA AFTER SLIDER CHANGE */
    setAll () {
        let activeSlide = this.sliderSwiper.el.querySelector(`${this.settings.sliderItemSelector}.swiper-slide-active`);
        this.currentYear = parseInt(activeSlide.getAttribute(this.settings.yearAttr));
        this.currentIndex = this.sliderSwiper.activeIndex;
        this.itemsLength = this.sliderSwiper.slides.length;

        window.performanceLoad.runProcessing();

        this.setButtons();
    }

    /** SET CURRENT YEAR BUTTON */
    setButtons() {
        for (let Button of this.buttonsYear){
            if (Button.hasAttribute(this.settings.yearAttr) && parseInt(Button.getAttribute(this.settings.yearAttr)) === this.currentYear){
                Button.classList.add(this.settings.btnActiveClass);
            } else {
                Button.classList.remove(this.settings.btnActiveClass);
            }
        }
    }
}

export default History;