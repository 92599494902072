/** VENDOR */
import { getCookie } from "../../../vendor/FPWDCookie/FPWDCookie";

/** CONFIG */
import ROUTING from "../Config/Routing";

/** HELPERS */
import { ajax } from "../Helpers/Ajax";
import { getElementParent } from "../Helpers/Element";
import { GetRouting } from "../Helpers/GetRouting";

/** COMPONENTS */
import { AddButtonLoader, RemoveButtonLoader } from "./Button";
import { BoxToast, BoxLoading, BoxToastError } from "./Box";
import InputCount from "./InputCount";


/**
* Add/remove product facrory
* @param {String} URL
* @param {Object} Data
* @param {String} Method
* @param {Element} Button
*/
export function ShoppingCartProductFactory(URL = ROUTING.shopping_cart, Data = {}, Method = "POST", Button = null) {
   return new Promise((resolve, reject) => {
        AddButtonLoader(Button);
        ajax({
            method: Method,
            url: URL,
            data: Data
        }).then((response) => {
            if (response.success){
                resolve(response);
            }
    
            if (typeof response.message !== "undefined"){
                BoxToast(response.message, response.success ? 'success' : 'error');
            }
    
            RemoveButtonLoader(Button);
        }).catch((error) => {
            RemoveButtonLoader(Button);
            BoxToastError(error.message);
            reject(error);
        });
   })
}


const ShoppingCartDefault = {
    basketSelector: '.js--basket-form',
    basketCountInputSelector: '.js--product-count-basket',
    smallBasketSelector: '.js--small-basket',
    smallBasketPopupSelector: '.js--small-basket-popup',
    smallBasketCountSelector: '.js--small-basket-count',
    smallBasketContentSelector: '.js--small-basket-content',
    addProductSelector: '.js--add-product',
    removeProductSelector: '.js--btn-remove-product',
    quantityProductSelector: '.js--btn-quantity-product',
    btnAddProductSelector: '.js--btn-add-product',
    inputCountSelector: '.js--input-count',
    inputApountSelector: '.js--payment-amount-field',
    paymentSectionSelector: '.js--payment-section',
    idAttr: 'data-id',
    keyAttr: 'data-key',
    formatAttr: 'data-template',
    setQuantityAttr: 'data-set-quantity',
    cookieItemsCountName: 'cart_items_count'
};

class ShoppingCart {
    constructor () {
        this.settings = ShoppingCartDefault;
        this.initState();
        this.init();
    }

    init() {
        this.smallBasket = document.querySelector(this.settings.smallBasketSelector);
        this.smallBasketPopup = document.querySelector(this.settings.smallBasketPopupSelector);
        this.basket = document.querySelector(this.settings.basketSelector);
        this.paymentSection = document.querySelector(this.settings.paymentSectionSelector);

        this.initInputCount();
        this.events();

        if (this.smallBasket && this.smallBasketPopup){
            this.smallBasketCount = document.querySelectorAll(this.settings.smallBasketCountSelector);
            this.smallBasketContent = this.smallBasketPopup.querySelector(this.settings.smallBasketContentSelector);

            this.getBasketItems();
        }
    }

    /**
     * Events
     */
    events() {
        document.body.addEventListener('click', (event) => {
            let element = event.target;
            let finded = false;

            while (element !== null && !finded) {
                if(element.matches(this.settings.btnAddProductSelector)){
                    finded = true;
                    this.onClickBtnAddProduct(element);
                } else if(element.matches(this.settings.removeProductSelector)){
                    finded = true;
                    this.onClickBtnRemoveProduct(element);
                } else if(element.matches(this.settings.quantityProductSelector)){
                    finded = true;
                    this.onClickBtnQuantityProduct(element);
                }
                element = element.parentElement;
            }
        }, false);

        // Get small basket on header popup show
        window.addEventListener('HeaderPopupShow', (event) => {
            if (!this.state.isLoaded && event.detail === 'small_basket') {
                this.updateShoppingCart(true);
            }
        });
    }

    initInputCount() {
        if (this.basket){
            this.inputCount = new InputCount(this.settings.basketCountInputSelector);
            this.inputCount.on('changeValue', ({ InputCount, value }) => {
                const productId = InputCount.wrapper.getAttribute(this.settings.idAttr);
                if (productId){
                    this.updateProductCountInShoppingCart(productId, value, InputCount.wrapper);
                }
            });
        }
    }

    /**
     * On click add to shopping cart buttton
     * @param {Element} Button
     */
    onClickBtnAddProduct(Button){
        let parent = getElementParent(Button, this.settings.addProductSelector);
        let ProductId = parseInt(Button.getAttribute(this.settings.idAttr));
        let Quantity = 1;

        // if parent box is undefined -> add product with quantity 1
        if (!parent){
            this.addProductToShoppingCart(ProductId, Quantity, Button);
            return;
        }

        let inputCount = parent.querySelector(this.settings.inputCountSelector);

        // if input count is defined -> get quantity value
        if (inputCount){
            Quantity = parseInt(inputCount.value);
        }

        // if Quantity < 1 -> set Quantity on 1
        if (Quantity < 1){
            Quantity = 1;
        }

        this.addProductToShoppingCart(ProductId, Quantity, Button);
    }

    /**
     * On click remove from shopping cart buttton
     * @param {Element} Button
     */
    onClickBtnRemoveProduct(Button){
        const key = Button.getAttribute(this.settings.keyAttr);
        this.removeProductFromShoppingCart(key, Button);
    }

    /**
     * On click change quantity product
     * @param {Element} Button
     */
    onClickBtnQuantityProduct(Button) {
        const key = Button.getAttribute(this.settings.keyAttr);
        const quantity = Button.getAttribute(this.settings.setQuantityAttr);
        this.updateProductCountInShoppingCart(key, quantity, Button);
    }

    /**
     * Get basket count from cookie
     */
    getBasketItems() {
        let count = getCookie(this.settings.cookieItemsCountName);

        if (count === null){
            this.setState({
                count_items: 0
            });

            return 0;
        }

        count = parseInt(count);
        this.setState({
            count_items: count
        });

        return count;
    }

    /**
     * Update small basket
     */
    updateShoppingCart(updateOnlySmallBasket = false) {
        this.setState({
            html: BoxLoading(),
            updateOnlySmallBasket: updateOnlySmallBasket,
            basketListHtml: null
        });

        ajax({
            method: 'GET',
            url: ROUTING.shopping_cart
        }).then((response) => {
            this.setState(Object.assign({}, { isLoaded: true }, response));
        }).catch((error) => BoxToastError(error.message));
    }

    /**
     * Add product to shopping cart
     * @param {Int} ProductId
     * @param {Int} Quantity
     * @param {Element} Button
     */
    addProductToShoppingCart(ProductId = null, Quantity = 1, Button = null) {
        if (ProductId !== null && Quantity > 0){
            ShoppingCartProductFactory(
                ROUTING.shopping_cart,
                {
                    product_id: ProductId,
                    quantity: Quantity
                },
                "POST",
                Button
            ).then(() => this.updateShoppingCart());
        }
    }

    /**
     * Remove product from shopping cart
     * @param {String} Key
     * @param {Element} Button
     */
    removeProductFromShoppingCart (Key = null, Button = null) {
        if (Key !== null){
            ShoppingCartProductFactory(
                GetRouting('shopping_cart_remove', { KEY: Key }),
                {},
                "DELETE",
                Button
            ).then(() => this.updateShoppingCart());
        }
    }

    updateProductCountInShoppingCart(productId = null, quantity = 1, Button = null) {
        if (productId){
            ShoppingCartProductFactory(
                GetRouting('shopping_cart_product_quantity', { product_id: productId }),
                {
                    quantity: quantity
                },
                "POST",
                Button
            ).then(() => this.updateShoppingCart());
        }
    }

    /**
     * Set small basket count
     */
    setSmallBasket() {
        if (this.smallBasketCount.length > 0){
            for (let item of this.smallBasketCount){

                if (item.hasAttribute(this.settings.formatAttr)){
                    let format = item.getAttribute(this.settings.formatAttr);
                    item.innerHTML = format.replace(/:value/g, this.state.count_items);
                } else {
                    item.innerHTML = this.state.count_items;
                }

                if (this.state.count_items > 0){
                    item.classList.remove('hidden');
                } else {
                    item.classList.add('hidden');
                }

            }
        }
    }

    /**
     * Set small basket content
     */
    setSmallBasketContent() {
        if (this.smallBasketContent){
            this.smallBasketContent.innerHTML = this.state.html;
        }
    }

    /**
     * Set input inside payments form
     */
    setTotalAmount() {
        const input = document.querySelector(this.settings.inputApountSelector);
        if (input && this.state.subtotal){
            input.value = this.state.subtotal;
        }
    }

    /**
     * Set basket content
     */
    setBasketContent() {
        if (
            this.state.count_items === 0 
            && this.state.isLoaded 
            && this.basket 
            && this.paymentSection 
            && !this.state.updateOnlySmallBasket
        ) {
            location.reload();
            return false;
        }

        if (this.basket && this.state.basketListHtml && !this.state.updateOnlySmallBasket){
            this.basket.innerHTML = this.state.basketListHtml;
            this.initInputCount();
        }
    }

    /**
     * Init state
     */
    initState() {
        this.state = {
            isLoaded: false,
            updateOnlySmallBasket: false,
            items: [],
            count_items: 0,
            points: 0,
            subtotal: 0,
            html: BoxLoading(),
            basketListHtml: null,
        };
    }

    /**
     * Set state
     * @param {Object} newState
     */
    setState(newState) {
        this.state = Object.assign({}, this.state, newState);

        this.setSmallBasket();
        this.setSmallBasketContent();
        this.setTotalAmount();
        this.setBasketContent();
    }
}

export default ShoppingCart;