/** VENDOR */
import FPWDValidate from '../../../vendor/FPWDValidate/FPWDValidate';

/** CONFIG */
import ROUTING from '../Config/Routing';

/** HELPERS */
import { ajax } from "../Helpers/Ajax";

/** COMPONENTS */
import { AddButtonLoader, RemoveButtonLoader } from '../Components/Button';

const CheckPointsDefault = {
    formBoxSelector: '.js--check-points-form-box',
    sendedBoxSelector: '.js--check-points-sended-box',
    formSelector: '.js--check-points-form',
    messageBoxSelector: '.js--check-points-message'
};

class CheckPoints {
    constructor (SectionSelector = '.js--check-points'){
        this.sections = document.querySelectorAll(SectionSelector);
        this.boxes = [];

        if (this.sections.length > 0){
            for (let Section of this.sections){
                this.boxes.push(new CheckPointsItem(Section, CheckPointsDefault));
            }
        }
    }
}

class CheckPointsItem{
    constructor (Section, Settings) {
        this.section = Section;
        this.settings = Settings;
        this.formBox = this.section.querySelector(this.settings.formBoxSelector);
        this.sendedBox = this.section.querySelector(this.settings.sendedBoxSelector);
        this.messageBox = this.section.querySelector(this.settings.messageBoxSelector);
        this.form = this.section.querySelector(this.settings.formSelector);

        if (this.form){
            this.init();
        }
    }

    /** CHECK AND SEND 'CHECK POINTS' FORM */
    init() {
        this.validate = new FPWDValidate(this.form, {
            dynamic: true
        });
        this.validate.on('validationSuccess', ({form, values}) => {
            const Button = form.querySelector('button[type="submit"]');
            AddButtonLoader(Button);

            ajax({
                url: ROUTING.check_points,
                method: 'GET',
                data: values,
            }).then((response) => {
                form.reset();
                RemoveButtonLoader(Button);

                if (response.message && this.messageBox && this.sendedBox){
                    this.messageBox.innerHTML = response.message;
                    this.formBox.classList.add('hidden');
                    this.sendedBox.classList.remove('hidden');
                }
            }).catch((error) => {
                RemoveButtonLoader(Button);
            });
        });
    }
}

export default CheckPoints;