import { getHeader, getHeaderBox } from "../Common/Header";
import { scrollPage, getScrollPosition } from "../Helpers/Scroll";
import { elementRect } from "../Helpers/Element";

const ScrollToSectionDotsdefault = {
    sectionScrollSelector: '[data-navigation-scroll]',
    navigationTitleAttr: 'data-navigation-title' 
};

class ScrollToSectionDots {
    constructor () {
        this.settings = ScrollToSectionDotsdefault;
        this.sections = document.querySelectorAll(this.settings.sectionScrollSelector);
        this.dorsWrapper = null;
        
        if (this.sections.length > 1) {
            this.checkActive();
            this.buildDots();
            this.events();
        }
    }

    buildDots() {
        this.dorsWrapper = document.createElement('ul');
        this.dorsWrapper.className = 'scroll-navigation-dots';

        let dots = '';
        for (let section of this.sections) {
            if (section.id && section.hasAttribute(this.settings.navigationTitleAttr)) {
                dots += 
                    `<li data-navigation-to="${section.id}" class="${this.state.activeSectionId === section.id ? 'active' : ''}">
                        <span class="bullet"></span>
                        <span class="title">${section.getAttribute(this.settings.navigationTitleAttr)}</span>
                    </li>`;
            }
        }

        this.dorsWrapper.innerHTML = dots;

        document.body.appendChild(this.dorsWrapper);
    }

    events () {
        this.dorsWrapper.addEventListener('click', (event) => {
            let element = event.target;
            let finded = false;

            while (element !== null && !finded) {
                if(element.matches('[data-navigation-to]')){
                    finded = true;
                    this.scrollToId(element.getAttribute('data-navigation-to'));
                }
                element = element.parentElement;
            }
        }, false);

        window.addEventListener('resize', this.checkActive.bind(this));
        window.addEventListener('scroll', this.checkActive.bind(this));
    }

    scrollToId (id) {
        const section = document.getElementById(id);
        if (section) {
            scrollPage(section, 400, 'linear');
        }
    }

    checkActive() {
        const scrollPosition = getScrollPosition().top + window.innerHeight / 2;
        let activeSectionId = null;
        for (let section of this.sections) {
            const sectionRect = elementRect(section);
            if (sectionRect.offset.top <= scrollPosition && sectionRect.offset.bottom >= scrollPosition) {
                activeSectionId = section.id;
                break;
            }
        }

        this.setState({
            activeSectionId: activeSectionId
        });
    }

    setActive() {
        if (!this.dorsWrapper){
            return;
        }

        const oldActive = this.dorsWrapper.querySelector('li.active');
        if (oldActive){
            oldActive.classList.remove('active');
        }

        if (!this.state.activeSectionId) {
            return;
        }

        const newActive = this.dorsWrapper.querySelector(`[data-navigation-to="${this.state.activeSectionId}"]`);
        if (newActive) {
            newActive.classList.add('active');
        }
    }

    initState () {
        this.state = {
            activeSectionId: ''
        };
    }

    setState (newState) {
        this.state = Object.assign({}, this.state, newState);

        this.setActive();
    }
};

export default ScrollToSectionDots;