import FPWDScriptLoader from "../../FPWDScriptLoader/FPWDScriptLoader";

/**
 * Define the version of the Google Pay API referenced when creating your
 * configuration
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/object#PaymentDataRequest|apiVersion in PaymentDataRequest}
*/
const BASE_REQUEST = {
    apiVersion: 2,
    apiVersionMinor: 0
};

const FPWD_GOOGLE_PAY_SCRIPTS = [
    'https://pay.google.com/gp/p/js/pay.js'
];

const FPWD_GOOGLE_PAY = {
    environment: '',
    buttonPaySelector: '.js--payment-button-googlepay',
    currency: 'PLN',

    /**
     * Merchant info object
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/object#MerchantInfo}
     */
    merchantId: null,
    /**
     * Card authentication methods supported by your site and your gateway
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/object#CardParameters}
     */
    allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
    /**
     * Card networks supported by your site and your gateway
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/object#CardParameters}
     */
    allowedCardNetworks: ['MASTERCARD', 'VISA'],

    gatewayMerchantId: null,
}


class FPWDGooglePay {
    constructor (Config = {}) {
        this.config = Object.assign({}, FPWD_GOOGLE_PAY, Config);
        this.buttons = document.querySelectorAll(this.config.buttonPaySelector);
        this.scriptLoader = new FPWDScriptLoader();
        this.dispatchHandlers = {};
        this.initState();
        this.init();
    }

    /**
     * Add payment scripts and run init function
     */
    init () {
        if (!this.state.scriptsIsLoaded){
            this.scriptLoader.load(FPWD_GOOGLE_PAY_SCRIPTS);
            this.scriptLoader.on('load', () => {
                this.setState({ scriptsIsLoaded: true });
                this.onGooglePayLoaded();
            });
        } else {
            this.onGooglePayLoaded();
        }
    }


    /**
     * Initialize Google PaymentsClient after Google-hosted JavaScript has loaded
     *
     * Display a Google Pay payment button after confirmation of the viewer's
     * ability to pay.
    */
    onGooglePayLoaded() {
        this.paymentsClient = new google.payments.api.PaymentsClient({
            environment: this.config.environment
        });

        this.paymentsClient.isReadyToPay(this.state.isReadyToPayRequest).then((response) => {
            if (response.result) {
                this.showPaymentButtons();
                this.prefetchPaymentData();
            } else {
                this.hidePaymentButtons();
            }
        }).catch((error) => {
            this.hidePaymentButtons();
            // show error in developer console for debugging
            this.emit('error', { paymentService: this, error: error });
            console.error(error);
        });
    }


    /**
     * Get tocken method
     *
     */
    getToken(totalPrice = null) {
        this.setTransactionInfo(totalPrice);
        this.paymentsClient.loadPaymentData(this.state.paymentDataRequest).then((paymentData) => {
            this.emit('success', {
                paymentService: this,
                environment: this.config.environment,
                merchantId: this.config.merchantId,
                data: {
                    paymentData: paymentData,
                    token: paymentData.paymentMethodData.tokenizationData.token
                }
            });
        }).catch((error) => {
            this.emit('error', { paymentService: this, error: error });
        });
    }


    /**
     * Show payment buttons -> if isReadyToPay result true
     */
    showPaymentButtons() {
        for (let button of this.buttons){
            button.classList.remove('hidden-payment-button');
        }
    }


    /**
     * Hide payment buttons -> if isReadyToPay result false or error
     */
    hidePaymentButtons() {
        for (let button of this.buttons){
            button.classList.add('hidden-payment-button');
        }
    }


    /**
     * Set transaktion info before generate token
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/object#TransactionInfo|TransactionInfo}
     */
    setTransactionInfo(totalPrice) {
        let transactionInfo = {
            totalPriceStatus: 'FINAL',
            totalPrice: totalPrice.toString(),
            currencyCode: this.config.currency
        }

        this.setState({
            paymentDataRequest: Object.assign({}, this.state.paymentDataRequest,  { transactionInfo: transactionInfo })
        });
    }


    /**
     * Prefetch payment data to improve performance
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/client#prefetchPaymentData|prefetchPaymentData()}
     */
    prefetchPaymentData() {
        let transactionInfo = {
            totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
            currencyCode: this.config.currency
        }

        this.setState({
            paymentDataRequest: Object.assign({}, this.state.paymentDataRequest,  { transactionInfo: transactionInfo })
        });

        this.paymentsClient.prefetchPaymentData(this.state.paymentDataRequest);
    }


    /**
     * INIT STATE
     */
    initState() {
        /**
         * Describe your site's support for the CARD payment method and its required
         * fields
         *
         * @see {@link https://developers.google.com/pay/api/web/reference/object#CardParameters|CardParameters}
         */
        const BASE_CARD_PAYMENT_METHOD = {
            type: 'CARD',
            parameters: {
                allowedAuthMethods: this.config.allowedAuthMethods,
                allowedCardNetworks: this.config.allowedCardNetworks
            }
        };


        /**
         * Identify your gateway and your site's gateway merchant identifier
         *
         * The Google Pay API response will return an encrypted payment method capable
         * of being charged by a supported gateway after payer authorization
         *
         * @see {@link https://developers.google.com/pay/api/web/reference/object#Gateway|PaymentMethodTokenizationSpecification}
         */
        const TOKENIZATION_SPECIFIKATION = {
            type: 'PAYMENT_GATEWAY',
            parameters: {
                'gateway': 'payu',
                'gatewayMerchantId': this.config.gatewayMerchantId
            }
        };


        /**
         * Describe your site's support for the CARD payment method including optional
         * fields
         *
         * @see {@link https://developers.google.com/pay/api/web/reference/object#CardParameters|CardParameters}
         */
        const CARD_PAYMENT_METHOD = Object.assign(
            {tokenizationSpecification: TOKENIZATION_SPECIFIKATION},
            BASE_CARD_PAYMENT_METHOD
        );


        /**
         * Configure your site's support for payment methods supported by the Google Pay
         * API.
         *
         * Each member of allowedPaymentMethods should contain only the required fields,
         * allowing reuse of this base request when determining a viewer's ability
         * to pay and later requesting a supported payment method
         *
         * @returns {object} Google Pay API version, payment methods supported by the site
         */
        const IS_READY_TO_PAY_REQUEST = Object.assign({}, BASE_REQUEST, { allowedPaymentMethods: [CARD_PAYMENT_METHOD] });


        /**
         * Configure support for the Google Pay API
         *
         * @see {@link https://developers.google.com/pay/api/web/reference/object#PaymentDataRequest|PaymentDataRequest}
         * @returns {object} PaymentDataRequest fields
         */
        const PAYMENT_DATA_REQUEST = {
            // SET 'environment'
            environment: this.config.environment,
            // SET 'apiVersion' and 'apiVersionMinor'
            apiVersion: BASE_REQUEST.apiVersion,
            apiVersionMinor: BASE_REQUEST.apiVersionMinor,
            // SET 'merchantInfo'
            merchantInfo: {
                merchantId: this.config.merchantId,
            },
            allowedPaymentMethods: [CARD_PAYMENT_METHOD]
        };


        this.state = {
            scriptsIsLoaded: false,
            paymentDataRequest: PAYMENT_DATA_REQUEST,
            isReadyToPayRequest: IS_READY_TO_PAY_REQUEST
        };
    }


    /**
     * SET STATE
     * @param {Object} newState
     */
    setState(newState) {
        this.state = Object.assign({}, this.state, newState);
    }

    /**
     * Event listener
     * @param {String} eventName
     * @param {Function} callback
     */
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    /**
     * Event emiter
     * @param {String} eventName
     * @param {Object} data
     */
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

export default FPWDGooglePay;