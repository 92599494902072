import '../../../vendor/Swiper/Swiper';
import ElementOnScroll from '../Helpers/Element';

const PersonTimelineDefault = {
    containerSelector: '.js--pt-container',
    itemSelector: '.js--pt-item',
    buttonSelector: '.js--pt-button',
    slidesPerView: 3
};

class PersonTimeline {
    constructor (BlockSelector = '.js--person-timeline', Settings = {}) {
        this.sections = document.querySelectorAll(BlockSelector);
        this.items = [];

        for (let Section of this.sections){
            this.items.push(new PersonTimelineItem(Section, Object.assign({}, PersonTimelineDefault, Settings)));
        }
    }
}

class PersonTimelineItem {
    constructor (Section, Settings) {
        this.section = Section;
        this.settings = Settings;
        this.currentIndex = 0;
        this.itemsCount = this.section.querySelectorAll(this.settings.itemSelector).length;
        this.button = this.section.querySelector(this.settings.buttonSelector);

        this.initSwiper();
        this.events();
    }

    events() {
        if (this.button){
            this.button.addEventListener('click', this.toNext.bind(this));
        }

        window.addEventListener('resize', this.setSizes.bind(this));
    }

    initSwiper() {
        this.section.classList.add('before-js-init');
        this.swiper = new Swiper(this.settings.containerSelector, {
            direction: 'vertical',
            slidesPerView: 'auto',
            slidesPerGroup: 1,
            mousewheel: true,
            init: false
        });

        this.swiper.on('init', () => {
            this.setSizes();
        });

        this.swiper.on('slideChangeTransitionStart', () => {
            this.onSwiperChange();
            this.setSizes();
        });

        this.swiper.init();
    }

    toNext() {
        if (this.currentIndex === this.itemsCount - this.settings.slidesPerView) {
            this.currentIndex = 0;
        } else {
            this.currentIndex++;
        }
        this.swiper.slideTo(this.currentIndex);
        this.onSwiperChange();
        // this.setSizes();
    }

    onSwiperChange() {
        if (this.currentIndex === this.itemsCount - this.settings.slidesPerView){
            this.button.innerHTML = `${__jsVars.l10n.buttons.latest}<i class="icon-arrow-top"></i>`;
        } else {
            this.button.innerHTML = `${__jsVars.l10n.buttons.older}<i class="icon-arrow-down"></i>`;
        }
    }

    setSizes() {
        for (let Slide of this.swiper.el.querySelectorAll(this.settings.itemSelector)){
            Slide.style.width = `${this.section.offsetWidth}px`;
        }
        this.swiper.el.style.height = `${this.getItemsHeight(this.currentIndex, this.currentIndex + this.settings.slidesPerView)}px`;
        this.swiper.update();
        setTimeout(() => {
            this.swiper.update();
        }, 400)
    }   

    getItemsHeight(from, to) {
        let height = 0;
        for (let i = from; i < to; i++){
            let item = this.section.querySelector(`${this.settings.itemSelector}[data-index="${i}"]`);
            height += item ? item.clientHeight : 0;
        }

        return height;
    }
}

export default PersonTimeline;