import { getHeader, getHeaderBox } from "../Common/Header";
import { elementRect } from "./Element";

// GET WINDOW SCROLL
export function getScrollPosition() {
    return {
        top: Math.ceil(window.pageYOffset || document.documentElement.scrollTop),
        left: Math.ceil(window.pageXOffset || document.documentElement.scrollLeft)
    };
}

export function LocationScroll () {
    let hash = window.location.hash;

    document.body.addEventListener('click', (event) => {
        let element = event.target;
        let finded = false;

        while (element !== null && !finded) {
            if(element.matches('a')){
                finded = true;
                onLinkClick(element);
            }
            element = element.parentElement;
        }
    }, false);

    window.addEventListener("hashchange", () => {
        if (window.location.hash !== ''){
            scrollToElem(window.location.hash, false);
        } else {
            scrollPage(0, 400, 'linear');
        }
    }, false);

    const onLinkClick = (element) => {
        const targetHref = element.href;
        const hash = targetHref.substr(targetHref.indexOf("#"));

        if (targetHref.indexOf("#") >= 0 && document.getElementById(hash.split("#")[1])) {
            event.preventDefault();        
            scrollToElem(hash);
        }
    }

    const scrollToElem = (hash, changeHash = true) => {
        const section = document.getElementById(hash.split("#")[1]);

        if(section){
            scrollPage(section, 400, 'linear', 0, () => {
                if (changeHash && window.location.hash !== hash){
                    history.pushState({}, '', hash);
                }
            })
        }
    }
    
    if(hash){
        scrollToElem(hash);
    }
}

/**
 * Smooth scroll to element
 * @param destination
 * @param duration
 * @param callback
 */
const easings = {
    linear: function(t) {
        return t;
    },
    easeInQuad: function(t) {
        return t * t;
    },
    easeOutQuad: function(t) {
        return t * (2 - t);
    },
    easeInOutQuad: function(t) {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    easeInCubic: function(t) {
        return t * t * t;
    },
    easeOutCubic: function(t) {
        return (--t) * t * t + 1;
    },
    easeInOutCubic: function(t) {
        return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    easeInQuart: function(t) {
        return t * t * t * t;
    },
    easeOutQuart: function(t) {
        return 1 - (--t) * t * t * t;
    },
    easeInOutQuart: function(t) {
        return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
    },
    easeInQuint: function(t) {
        return t * t * t * t * t;
    },
    easeOutQuint: function(t) {
        return 1 + (--t) * t * t * t * t;
    },
    easeInOutQuint: function(t) {
        return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
    }
};


let scrollKilled = false;
const killScroll = () => {
    scrollKilled = true;
};

document.addEventListener('mousedown', killScroll, false);
document.addEventListener("mousewheel", killScroll, false);
document.addEventListener("DOMMouseScroll", killScroll, false);

export function scrollPage(destination, duration, easing, offset = 0, callback) {
    const pageContent = document.querySelector('.js--page-content, .page-content');
    const content = document.querySelector('.js--content-wrapper');

    let firstSection = null;
    if (pageContent){
        firstSection = pageContent.getElementsByTagName('section');
    } else {
        firstSection = content.getElementsByTagName('section');
    }
    
    if (firstSection.length > 0){
        firstSection = firstSection[0];
    } else {
        firstSection = false;
    }

    scrollKilled = false;

    if (typeof duration !== 'number'){
        duration = 300;
    }

    if (typeof easing !== 'string' || Object.keys(easings).indexOf(easing) === -1){
        easing = 'linear';
    }

    if (typeof offset !== 'number'){
        offset = 0;
    }

    const start = getScrollPosition().top;
    const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

    const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
    const destinationOffset = typeof destination === 'number' ? destination : elementRect(destination).offset.top;
    
    const calcDestination = () => {
        const headerOffsert = getHeader().classList.contains('header-fixed') ? getHeaderBox().clientHeight : 0;
        let scroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset) + offset - headerOffsert;
        
        if (getHeader().classList.contains('header-fixed') && destination && !destination.isSameNode(firstSection) && scroll < firstSection.clientHeight) {
            scroll = firstSection.clientHeight;
        }

        if (typeof destination !== 'number' && destination.hasAttribute('data-scroll-offset')) {
            scroll += parseInt(destination.getAttribute('data-scroll-offset'));
        }

        return scroll;
    };
    
    let destinationOffsetToScroll = calcDestination();

    if (destinationOffsetToScroll < 0){
        destinationOffsetToScroll = 0;
    }

    if ('requestAnimationFrame' in window === false) {
        window.scroll(0, destinationOffsetToScroll);
        if (callback) {
            callback();
        }
        return;
    }

    function scroll() {
        const now = 'now' in window.performance ? performance.now() : new Date().getTime();
        const time = Math.min(1, ((now - startTime) / duration));
        const timeFunction = easings[easing](time);

        destinationOffsetToScroll = calcDestination();
        
        window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

        if (getScrollPosition().top === destinationOffsetToScroll) {
            if (callback) {
                callback();
            }
            return;
        }

        if (!scrollKilled){
            if ('scrollRestoration' in history) {
                history.scrollRestoration = 'manual';
            }
            requestAnimationFrame(scroll);
        }
    }

    scroll();
}
