const formatParams = (params, method) => {
    if (typeof params === 'string') {
        return params;
    }

    let letter = method.toUpperCase() === 'POST' ? '' : '?';
    if (Array.isArray(params)) {
        return letter + params.map(function (obj) {
            return obj.name + '=' + obj.value;
        }).join('&');
    }
    return letter + Object.keys(params).map(function (key) {
        return key + '=' + params[key];
    }).join('&');
};

const IsJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const ajaxDefault = {
    url: '/',
    data: {},
    method: 'POST',
    async: true,
    callback: (response) => {},
    error: (error) => {}
};

const ajaxHeadersDefault = {
    'Content-Type': 'application/x-www-form-urlencoded'
};

export function ajax (_options, _headers = {}) {
    return new Promise((resolve, reject) => {
        const options = Object.assign({}, ajaxDefault, _options);
        const headers = Object.assign({}, ajaxHeadersDefault, _headers);
        const xhr = new XMLHttpRequest();
        
        let params = '';
        let body = null;
        
        if (options.formData){
            body = new FormData();

            for (let Name in options.data){
                body.append(Name, options.data[Name]);
            }
        } else {
            if (options.method.toUpperCase() === 'POST') {
                body = formatParams(options.data, 'POST');
                params = '';
            } else {
                if (Object.keys(options.data).length > 0){
                    params = formatParams(options.data, 'GET');
                }
                body = null;
            }
        }

        xhr.open(options.method, options.url + params, options.async);
        if (!options.formData){
            for (let header in headers){
                xhr.setRequestHeader(header, headers[header]);
            }
        }
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(IsJsonString(xhr.response) ? JSON.parse(xhr.response) : xhr.response);
            } else {
                reject(IsJsonString(xhr.response) ? JSON.parse(xhr.response) : xhr.response);
            }
        };
        xhr.onerror = () => reject(IsJsonString(xhr.response) ? JSON.parse(xhr.response) : xhr.response);
        xhr.send(body);
    });
};