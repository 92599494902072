import ENV from "../Config/Env";
import Toastify from "../../../vendor/Toastify/Toastify";

export function BoxInfo (MESSAGE = '', CONTAINER = false) {
    return `
        ${CONTAINER ? '<div class="container-fluid">' : ''}
            <div class="box-base box-info">
                <i class="icon-info"></i>
                ${ MESSAGE }
            </div>
        ${CONTAINER ? '</div>' : ''}
    `;
}

export function BoxError (MESSAGE = '', CONTAINER = false) {
    return `
        ${CONTAINER ? '<div class="container-fluid">' : ''}
            <div class="box-base box-error">
                <i class="icon-alert"></i>
                ${ MESSAGE }
            </div>
        ${CONTAINER ? '</div>' : ''}
    `;
}

export function BoxLoading (CONTAINER = false) {
    return `
        ${CONTAINER ? '<div class="container-fluid">' : ''}
            <div class="box-base box-loading">
                <div class="loader-spin"></div>
            </div>
        ${CONTAINER ? '</div>' : ''}
    `;
}

export function BoxToast(MESSAGE = '', Type = 'success') {
    if (MESSAGE !== '' && typeof MESSAGE === 'string'){
        Toastify({ 
            text: MESSAGE,
            backgroundColor: ENV.backgroundColor[Type] || '',
            gravity: 'bottom',
            position: 'center',
            duration: 5000,
            close: true,
            stopOnFocus: true
        }).showToast();
    }
}

export function BoxToastError (MESSAGE){
    let message = __jsVars.l10n.errors.default;

    if (typeof MESSAGE !== "undefined"){
        message = MESSAGE;
    }

    BoxToast(message, 'error');
}