/** HELPERS */
import './Helpers/Polyfill';
import { LocationScroll } from './Helpers/Scroll';

/** VENDOR */
import FPWDAccordion from '../../vendor/FPWDAccordion/FPWDAccordion';
import FPWDDropdown from '../../vendor/FPWDDropdown/FPWDDropdown';
import { FPWDCollapseIt } from '../../vendor/FPWDCollapse/FPWDCollapse';

/** CONFIG */
import ENV from './Config/Env';
import ROUTING from './Config/Routing';

/** COMMON */
import Header from './Common/Header';

/** COMPONENTS */
import SliderBox from './Components/SliderBox';
import InputCount from './Components/InputCount';
import CheckPoints from './Components/CheckPoints';
import TasksSlider from './Components/TasksSlider';
import GetDescription from './Components/GetDescription';
import { ButtonScrollDown, ButtonScrollTo } from './Components/ButtonScroll';
import ShoppingCart from './Components/ShoppingCart';
import ShareBox from './Components/ShareBox';
import NeedHelp from './Components/NeedHelp';
import ScrollToSectionDots from './Components/ScrollToSectionDots';

/** SECTIONS */
import TopBanner from './Sections/TopBanner';
import ShoppingTypes from './Sections/ShoppingTypes';
import PersonTimeline from './Sections/PersonTimeline';
import AlertPopup from './Sections/AlertPopup';
import Catalog from './Sections/Catalog';
import Video from './Components/Video';
import HeartsSlider from './Sections/HeartsSlider';
import History from './Sections/History';
import RatingList from './Sections/RatingList';
import Search from './Sections/Search';
import StatusSection from './Sections/StatusSection';
import ProgressBar from './Sections/ProgressBar';

/** FORMS */
import { BaseForm } from './Forms/BaseForm';
import PaymentForm from './Forms/PaymentForm';
import Newsletter from './Forms/Newsletter';

/** STYLES */
import '../scss/Theme.scss';

class ThemeController {
    constructor (){

        /** VENDOR */
        new FPWDAccordion();
        new FPWDDropdown();
        FPWDCollapseIt('.js--collapse-button', 'data-collapse-it');

        /** COMMON */
        new Header();
        /** INIT COMPONENTS */
        new SliderBox('.js--swiper-slider-box');
        new SliderBox('.js--swiper-loyalty-items', {
            slidesPerView: 5,
            slidesPerGroup: 5,
            spaceBetween: 43,
            breakpoints: {
                [ENV.breakpoints['lg-max']]: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 30
                },
                [ENV.breakpoints['md-max']]: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 15
                },
                [ENV.breakpoints['sm-max']]: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 15
                },
                370: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 15
                }
            }
        });
        new SliderBox('.js--swiper-outside-container', {
            slidesPerView: 4,
            slidesPerGroup: 1,
            spaceBetween: 30,
            breakpoints: {
                [ENV.breakpoints['lg-max']]: {
                    slidesPerView: 3
                },
                [ENV.breakpoints['md-max']]: {
                    slidesPerView: 3,
                    spaceBetween: 15
                },
                [ENV.breakpoints['sm-max']]: {
                    slidesPerView: 2,
                    spaceBetween: 15
                },
                370: {
                    slidesPerView: 1,
                    spaceBetween: 15
                }
            }
        });
        new SliderBox('.js--swiper-partners', {
            slidesPerView: 8,
            spaceBetween: 30,
            slidesPerGroup: 1,
            loop: true,
            loopFillGroupWithBlank: true,
            navigation: {
                nextEl: '.js--swiper-partners-button-next',
                prevEl: '.js--swiper-partners-button-prev',
            },
            breakpoints: {
                [ENV.breakpoints['lg-max']]: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                    slidesPerGroup: 1,
                    loop: true,
                    loopFillGroupWithBlank: true,
                },
                [ENV.breakpoints['md-max']]: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                    slidesPerGroup: 1,
                    loop: true,
                    loopFillGroupWithBlank: true,
                },
                [ENV.breakpoints['sm-max']]: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    slidesPerGroup: 1,
                    loop: true,
                    loopFillGroupWithBlank: true,
                },
                370: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    slidesPerGroup: 1,
                    loop: true,
                    loopFillGroupWithBlank: true,
                }
            }
        });
        new TasksSlider();
        new InputCount();
        new GetDescription();
        new Video();
        new CheckPoints();
        new ShoppingCart();
        new ShareBox();
        new ScrollToSectionDots();
        new NeedHelp();
        ButtonScrollDown();
        ButtonScrollTo();
        LocationScroll();

        /** INIT SECTIONS */
        new TopBanner();
        new AlertPopup();
        new ShoppingTypes();
        new HeartsSlider();
        new Catalog('.js--persons-catalog', { apiUrl: ROUTING.get_kids_category });
        new Catalog('.js--blog', { apiUrl: ROUTING.get_blog });
        new Search();
        new PersonTimeline();
        new History();
        new RatingList();
        new StatusSection();
        new ProgressBar();

        /** FORMS */
        new BaseForm();
        new PaymentForm();
        new Newsletter();
    }
}

// Loader babel polyfill only if needed
const BROWSER_UNSUPPORT_FEATURES = !window.Promise || !window.fetch || !window.Symbol || !Object.assign;

if (BROWSER_UNSUPPORT_FEATURES) {
    const js = document.createElement('script');
    js.src = __jsVars.scripts.babelPolyfill;
    js.onload = function() {
        new ThemeController();
    };
    js.onerror = function() {
        new ThemeController();
        new Error('Failed to load script ' + __jsVars.scripts.babelPolyfill);
    };
    document.head.appendChild(js);
} else {
    new ThemeController();
}