import { bodyOverflow, setBodyFixed, unsetBodyFixed } from '../../src/js/Helpers/Body';
import './FPWDModal.scss';

const FPWDModalDefault = {
    class: '',
    closeIcon: 'icon-close',
    body: ''
};

class FPWDModal {
    constructor (Button = '.js-fpwd-modal', Settings = {}) {
        this.settings = Object.assign({}, FPWDModalDefault, Settings);
        this.dispatchHandlers = {};
        this.modals = [];

        if (!Button){
            return false;
        }

        if (Button instanceof Element){
            this.button = Button;
            this.button.addEventListener('click', this.createModal.bind(this));
        } else {
            this.buttons = document.querySelectorAll(Button);
            for (let ButtonItem of this.buttons){
                ButtonItem.addEventListener('click', this.createModal.bind(this));
            }
        }
    }

    setOptions(Settings){
        this.settings = Object.assign({}, this.settings, Settings);
    }

    createModal() {
        const Modal = new FPWDModalItem(this.settings, this.modals.length);
        Modal.on('show', (data) => {
            this.emit('show', data, this);
        }).on('close', (data) => {
            this.removeModalObject(data);
            this.emit('close', this);
        });
        
        this.modals.push(Modal);
    }

    close() {
        for (let Modal of this.modals){
            Modal.closePopup();
        }
    }

    removeModalObject(data){
        for (let i in this.modals){
            if (this.modals[i].index === data.index){
                this.modals[i] = null;
                this.modals.splice(i, 1);
            }
        }
    }

    // Listener
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    // Emiter
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

class FPWDModalItem {
    constructor(Settings, Index){
        this.index = Index;
        this.settings = Settings;
        this.dispatchHandlers = [];
        this.appendModal();
    }

    appendModal() {
        this.modal = document.createElement('div');
        this.modal.className = `fpwd-modal ${this.settings.class}`;
        this.modal.innerHTML = this.getMackup();
        document.body.appendChild(this.modal);
        bodyOverflow(true);

        setTimeout(() => {
            this.modal.classList.add('modal-show');
            this.emit('show', this);
            setBodyFixed()
        }, 100);
        

        this.buttonClose = this.modal.querySelector('.fpwd-modal-close');
        this.overlay = this.modal.querySelector('.fpwd-modal-overlay');
        

        if (this.buttonClose){
            this.addEventClose();
        }
    }

    addEventClose() {
        this.buttonClose.addEventListener('click', this.closePopup.bind(this), false);
        this.overlay.addEventListener('click', this.closePopup.bind(this), false);
    }

    closePopup() {
        unsetBodyFixed();
        this.buttonClose.removeEventListener('click', this.closePopup.bind(this), false);
        this.overlay.removeEventListener('click', this.closePopup.bind(this), false);
        this.modal.classList.remove('modal-show');
        bodyOverflow(false);
        setTimeout(() => {
            document.body.removeChild(this.modal);
        }, 500);
        this.emit('close', this);
        delete this;
    }

    getMackup() {
        return `
            <div class="fpwd-modal-overlay"></div>
            <div class="fpwd-modal-container">
                <div class="container-fluid">
                    <div class="fpwd-modal-body">
                        <button type="button" class="fpwd-modal-close">
                            <i class="${this.settings.closeIcon}"></i>
                        </button>
                        ${this.settings.body}
                    </div>
                </div>
            </div>
        `;
    }

    // Listener
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    // Emiter
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

export default FPWDModal;