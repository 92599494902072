import { getScrollPosition } from "./Scroll";


/**
 * GET ELEMENT PARENT
 */
export function getElementParent(element, selector) {
    if (element){
        for ( ; element && element !== document; element = element.parentNode ) {
            if (element.matches(selector)) return element;
        }
        return null;
    } else {
        return null;
    }
}

/**
 * GET ELEMENT OFFSET POSITION
 */
export function elementRect(element) {
    if (element){
        const scroll = getScrollPosition();
        const rect = element.getBoundingClientRect();

        return {
            top: rect.top,
            bottom: rect.bottom,
            left: rect.left,
            right: rect.right,
            width: rect.width,
            height: rect.height,
            x: rect.x,
            y: rect.y,
            offset: {
                top: rect.top + scroll.top,
                bottom: rect.bottom + scroll.top,
                left: rect.left + scroll.left,
                right: rect.right + scroll.left
            }
        };    
    } else {
        return null;
    }
}

/** TRIGGER EVENT */
export function triggerEvent(element, event){
    if ('createEvent' in document) {
         // modern browsers, IE9+
         var e = document.createEvent('HTMLEvents');
         e.initEvent(event, false, true);
         element.dispatchEvent(e);
     }
 }