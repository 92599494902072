/** VENDOR */
import '../../../vendor/Swiper/Swiper';

class TasksSlider {
    constructor (SwiperSelector = '.js--swiper-tasks') {
        this.swiper = new Swiper(SwiperSelector, {
            slidesPerView: 'auto',
            freeMode: true
        });
    }
}

export default TasksSlider;