/** VENDOR */
import FPWDModal from "../../../vendor/FPWDModal/FPWDModal";
import FPWDValidate from "../../../vendor/FPWDValidate/FPWDValidate";

/** CONFIG */
import ROUTING from "../Config/Routing";

/** HELPERS */
import { ajax } from "../Helpers/Ajax";
import { onRequestError, onRequestSuccess } from "../Helpers/Request";

/** COMPONENTS */
import { AddButtonLoader } from "./Button";

const ShareBoxDefault = {
    shareEmailSelector: '.js--show-share-email',
    shareEmailPopupSelector: '.js--form-share-email',
    shareEmailFormSelector: '.js--share-email'
};

class ShareBox {
    constructor () {
        this.settings = ShareBoxDefault;
        this.shareEmailModal = null;
        this.shareEmailValidator = null;

        if (document.querySelectorAll(this.settings.shareEmailSelector).length > 0){
            this.initEmailShare();
        }
    }

    /**
     * Init email share modal
     */
    initEmailShare() {
        this.shareEmailModal = new FPWDModal(this.settings.shareEmailSelector, {
            class: 'modal-small',
            body: document.querySelector(this.settings.shareEmailPopupSelector).innerHTML
        });

        this.shareEmailModal.on('show', (data) => {
            this.initEmailValidate(data.modal);
        });
    }

    /**
     * Init validation and send form
     * @param {Element} Modal 
     */
    initEmailValidate(Modal) {
        const Form = Modal.querySelector(this.settings.shareEmailFormSelector);

        if (Form){
            const Submit = Form.querySelector('button[type="submit"]');

            this.shareEmailValidator = new FPWDValidate(Form, {
                dynamic: true
            }).on('validationSuccess', ({form, values}) => {
                AddButtonLoader(Submit);

                ajax({
                    method: "POST",
                    url: ROUTING.share_email,
                    data: values
                })
                .then((response) => {
                    this.shareEmailModal.close();
                    Form.reset();
                    onRequestSuccess(response);
                })
                .catch((error) => onRequestError(error, Submit));
            });
        }
    }
}

export default ShareBox;