/** VENDOR */
import IMask from 'imask';
import datepicker from 'js-datepicker';
import FPWDValidate from '../../../vendor/FPWDValidate/FPWDValidate';
import FPWDFileUpload from '../../../vendor/FPWDFileUpload/FPWDFileUpload';

/** CONFIG */
import MASK from "../Config/Mask";
import DATEPICKER from "../Config/Datepicker";

/** HELPERS */
import { scrollPage } from "../Helpers/Scroll";
import { triggerEvent, getElementParent } from '../Helpers/Element';
import { ajax } from "../Helpers/Ajax";
import { onRequestError } from '../Helpers/Request';
import { SaveUserData, AutocompleteForm } from '../Helpers/SaveUserData';

/** COMPONENTS */
import { AddButtonLoader, RemoveButtonLoader } from '../Components/Button';
import { BoxToastError, BoxToast } from '../Components/Box';
import CheckboxGroup from '../Components/CheckboxGroup';

const FormDefault = {
    inputMaskAttr: 'data-input-mask',
    inputMaskCustomAttr: 'data-mask-pattern',
    inputDatepickerSelector: '.js--datepicker',
    wrapperSectionSelector: '.js--section-form',
    buttonToShowFormSelector: '.js--show-form',
    formThanksSelector: '.js--form-thanks',
    fileUploadSelector: '.js--file-upload'
};

class BaseForm {
    constructor (FormSelector = '.js--form') {
        this.sections = document.querySelectorAll(FormSelector);
        this.items = [];

        if(this.sections.length > 0){
            for (let Form of this.sections){
                this.items.push(new BaseFormItem(Form, FormDefault));
            }
        }
    }
}

class BaseFormItem {
    constructor (Form, Settings = {}) {
        this.form = Form;
        this.settings = Object.assign({}, FormDefault, Settings);
        this.masks = [];
        this.datepickers = [];
        this.formData = {};
        this.formDataFiles = {};

        if (this.form){
            this.init();
        }
    }

    init() {
        AutocompleteForm(this.form);
        this.sectionWrapper = getElementParent(this.form, this.settings.wrapperSectionSelector);
        if (this.sectionWrapper){
            this.buttonShowForm = this.sectionWrapper.querySelector(this.settings.buttonToShowFormSelector);
            this.buttonWrapper = getElementParent(this.buttonShowForm, '.section-buttons');
            this.formThanks = this.sectionWrapper.querySelector(this.settings.formThanksSelector);
        }

        this.dynamic = this.form.classList.contains('js--dynamic-form');
        this.action = this.form.getAttribute('action');
        this.submit = this.form.querySelector('button[type="submit"]');
        this.uploaderBox = this.form.querySelector(this.settings.fileUploadSelector);
        this.uploader = null;

        // Check all
        this.checkAll = new CheckboxGroup();
        
        this.events();
        this.initDatePicker();
        this.initInputMask();
        this.validateForm();

        if (this.uploaderBox){
            this.initUploader();
        }
    }

    events() {
        if (this.buttonShowForm){
            this.buttonHiddenOnShow = this.buttonShowForm.classList.contains('hidden-on-form-show');
            this.buttonShowForm.addEventListener('click', this.toggleForm.bind(this));
        }
    }

    /**
     * Init file uploader
     */
    initUploader() {
        this.uploader = new FPWDFileUpload(this.uploaderBox, {
            required: true
        });

        // if has uploader -> set `formDataFiles` on append files
        if (this.uploader){
            this.uploader.on('validationError', ({ Uploader, File, Type }) => {
                BoxToastError(Uploader.getErrorMessage(Type, File));
            }).on('appendAll', ({ Files }) => {
                this.formDataFiles = Files;
            }).on('removeFile', ({ Files }) => {
                this.formDataFiles = Files;
            });
        }
    }

    /**
     * Validate form
     */
    validateForm() {
        this.validate = new FPWDValidate(this.form, {
            dynamic: this.dynamic
        }).on('validationSuccess', ({form, values}) => {
            if (this.dynamic && (!this.uploader || this.uploader.getItem(0).checkValid())){
                this.formData = values;
                AddButtonLoader(this.submit);
                this.sendForm();
            }
        }).on('validationError', ({form, errors}) => {
            let firstError = form.querySelectorAll('.js-validate-error-field-parent');
            if (firstError[0]){
                scrollPage(firstError[0], 400, 'linear');
            }
        });
    }

    /**
     * Send form
     */
    sendForm() {
        SaveUserData(this.formData);
        ajax({
            url: this.action,
            method: 'POST',
            data: Object.assign({}, this.formData, this.formDataFiles),
            formData: true
        })
        .then((response) => this.onFormSended(response))
        .catch((error) => onRequestError(error, this.submit));
    }

    /**
     * Init inputs datepickers
     */
    initDatePicker() {
        this.inputsDatepicker = this.form.querySelectorAll(this.settings.inputDatepickerSelector);
        if (this.inputsDatepicker.length > 0){
            for (let Input of this.inputsDatepicker){
                let picketOptions = {
                    onSelect: (instance) => {
                        triggerEvent(instance.el, 'change');
                    }
                };

                if (Input.hasAttribute('data-from-today')) {
                    picketOptions.minDate = new Date();
                }

                const picker = datepicker(Input, Object.assign({}, DATEPICKER, picketOptions));

                picker.el.addEventListener('keyup', () => {
                    let value = event.target.value;
                    if (moment(value).isValid()){
                        picker.setDate(new Date(moment(value)), true)
                    }
                });

                this.datepickers.push(picker);
            }
        }
    }

    /**
     * Init inputs masks
     */
    initInputMask () {
        this.inputsMask = this.form.querySelectorAll(`[${this.settings.inputMaskAttr}]`);
        
        for (let Input of this.inputsMask){
            let mask = Input.getAttribute(this.settings.inputMaskAttr);
            let options;

            if (mask === 'custom'){
                options = {
                    mask: Input.getAttribute(this.settings.inputMaskCustomAttr)
                }
            } else {
                options = MASK[mask];
            }

            this.masks.push(IMask(Input, options));
        }
    }

    /**
     * Toggle form visible
     */
    toggleForm() {
        if (this.form.classList.contains('hidden')){
            this.showForm();
        } else {
            this.hideForm();
        }
    }

    /**
     * Show form
     */
    showForm() {
        if (this.buttonHiddenOnShow){
            if (this.buttonWrapper){
                this.buttonWrapper.classList.add('hidden');
            } else {
                this.buttonShowForm.classList.add('hidden');
            }
        }

        this.form.classList.remove('hidden');
        this.form.classList.add('form-hidden-after-send');
        scrollPage(this.form, 400, 'linear');
    }

    /**
     * Hide form
     */
    hideForm() {
        if (this.buttonWrapper){
            this.buttonWrapper.classList.remove('hidden');
        } else if (this.buttonShowForm) {
            this.buttonShowForm.classList.remove('hidden');
        }

        this.form.classList.add('hidden');
        this.form.classList.remove('form-hidden-after-send');
    }

    onFormSended(response) {
        RemoveButtonLoader(this.submit);

        // if has message -> push message and set timeout for redirect
        if (typeof response.message !== "undefined"){
            BoxToast(response.message, 'success');
        }

        // if has file uploader -> clean file uploader
        if (this.uploader){
            this.uploader.getItem(0).reset();
        }

        /** reset form */
        this.form.reset();

        /** hide form */
        if (this.form.classList.contains('form-hidden-after-send')){
            this.hideForm();
        }

        // if box thanks is defined -> show thanks box 
        if (this.formThanks){
            this.formThanks.classList.remove('hidden');
            scrollPage(this.formThanks, 400, 'linear');
        }
    }
}

export {
    BaseForm,
    BaseFormItem,
}