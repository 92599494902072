import { BoxToast } from "../Components/Box";
import { RemoveButtonLoader } from "../Components/Button";

export function onRequestSuccess(response) {
    // if has message -> push message and set timeout for redirect
    if (typeof response.message !== "undefined"){
        BoxToast(response.message, 'success');
    }
}

export function onRequestError(error, Button = null) {
    let message = __jsVars.l10n.errors.default;

    if (typeof error.message !== "undefined"){
        message = error.message;
    }

    console.error(error);
    BoxToast(message, 'error');
    RemoveButtonLoader(Button);
}