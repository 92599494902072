const API_URL = __jsVars.url.api;

const ROUTING = {
    newsletter: `${API_URL}v1/newsletter/save`,
    check_points: `${API_URL}v1/heart/check-points`,
    get_team_description: `${API_URL}v1/team/{ID}/`,
    get_product_description: `${API_URL}v1/shop/product/{ID}/{quantity}/`,
    get_loyalty_product_description: `${API_URL}v1/heart/loyalty_product/{ID}/`,
    get_rating_content: `${API_URL}v1/ranking/{ID}/`,
    get_achievements: `${API_URL}v1/history/achievements/{YEAR}/`,
    shopping_cart: `${API_URL}v1/shop/cart`,
    shopping_cart_remove: `${API_URL}v1/shop/cart/{KEY}`,
    shopping_cart_product_quantity: `${API_URL}v1/shop/cart/{product_id}/`,
    share_email: `${API_URL}v1/share/email`,
    get_kids_category: `${API_URL}v1/kids`,
    get_blog: `${API_URL}v1/blog`,
    apple_pay_session_url: `${API_URL}v1/validate/apple_pay/`,
    get_kids_need_help: `${API_URL}v1/kids/need-help`,
};

export default ROUTING;