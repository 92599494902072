import './FPWDCollapse.scss';

export const FPWDCollapseIt = (ButtonSelector = '.js--collapse-button', CollapseAttr = 'data-collapse-it') => {
    document.body.addEventListener('click', (event) => {
        let element = event.target;
        let finded = false;

        while (element !== null && !finded) {
            if(element.matches(ButtonSelector)){
                event.preventDefault();
                finded = true;
                if (element.hasAttribute(CollapseAttr)) {
                    FPWDCollapse(element.getAttribute(CollapseAttr), 'height 0.2s ease-out', element);
                }
            }
            element = element.parentElement;
        }
    }, false);
}

export const FPWDCollapse = (Section = null, transition = 'height 0.2s ease-out', Button = null) => {
    let sectionElement;
    
    if (typeof Section === 'string') {
        sectionElement = document.getElementById(Section);
    } else if (Section instanceof Element) {
        sectionElement = Section;
    } else {
        return false;
    }
   
    if (!sectionElement) {
        return false;
    }

    const insideContent = sectionElement.querySelector('.js--collapse-content');
    if (!insideContent) {
        return false;
    }

    sectionElement.style.transition = transition;

    if (sectionElement.classList.contains('is-collapsed')) {
        sectionElement.style.height = `0px`;
        sectionElement.classList.remove('is-collapsed');
        if (Button) {
            Button.innerText = __jsVars.l10n.buttons.readMore;
        }
        window.removeEventListener('resize', FPWDCollapseResize(sectionElement, insideContent));
    } else {
        sectionElement.style.height = `${insideContent.scrollHeight}px`;
        sectionElement.classList.add('is-collapsed');
        if (Button) {
            Button.innerText = __jsVars.l10n.buttons.readLess;
        }
        window.addEventListener('resize', FPWDCollapseResize(sectionElement, insideContent));
    }
};

const FPWDCollapseResize = (section, content) => {
    if (section.classList.contains('is-collapsed')) {
        section.style.height = `${content.scrollHeight}px`;
    }
}