const FPWDValidateDefault = {
    dynamic: false,
    addErrorToParent: '.form-group',
    validationFieldSelector: '.form-control',
    validationAttr: 'data-validate-field',
    validationExpyMonthAttr: 'data-expy-month-field',
    validationExpyYearAttr: 'data-expy-year-field',
    errorInputClassName: 'js-validate-error-field',
    errorClassName: 'js-validate-error-label',
};

const REGEXPDefault = {
    number: /^\d+$/,
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    phone: /^(?:[-\.\(\)\s]*(\d)){9}\)?$/,
    zip: /^\d{2}(-\d{3})?$/,
    date: /^\s*(3[01]|[12][0-9]|0?[1-9])\/(1[012]|0?[1-9])\/((?:19|20|21)\d{2})\s*$/
};

const MESSAGESDefault = {
    required: __jsVars.l10n.validation.required,
    minlength: __jsVars.l10n.validation.minlength,
    maxlength: __jsVars.l10n.validation.maxlength,
    email: __jsVars.l10n.validation.email,
    phone: __jsVars.l10n.validation.phone,
    zip: __jsVars.l10n.validation.zip,
    date: __jsVars.l10n.validation.date,
    cardNumber: __jsVars.l10n.validation.cardNumber,
    day: __jsVars.l10n.validation.day,
    month: __jsVars.l10n.validation.month,
    price: __jsVars.l10n.validation.price,
    cardExpy: __jsVars.l10n.validation.cardExpy
};

const validationCreditCard = (value) => {
    // Accept only digits, dashes or spaces
      if (/[^0-9-\s]+/.test(value)) return false;
  
      // The Luhn Algorithm. It's so pretty.
      let nCheck = 0, bEven = false;
      value = value.replace(/\D/g, "");
  
      for (var n = value.length - 1; n >= 0; n--) {
          var cDigit = value.charAt(n),
                nDigit = parseInt(cDigit, 10);
  
          if (bEven && (nDigit *= 2) > 9) nDigit -= 9;
  
          nCheck += nDigit;
          bEven = !bEven;
      }
  
      return (nCheck % 10) == 0;
  }

class FPWDValidate {
    constructor (Form = '.js--form', Settings = {}, MESSAGES = {}, REGEXP = {}) {
        this.settings = Object.assign({}, FPWDValidateDefault, Settings);
        this.MESSAGES = Object.assign({}, MESSAGESDefault, MESSAGES);
        this.REGEXP = Object.assign({}, REGEXPDefault, REGEXP);
        this.items = [];
        this.dispatchHandlers = {};

        if (Form instanceof Element){
            this.init(Form, );
        } else {
            this.forms = document.querySelectorAll(Form);
            
            if (this.forms.length > 0){
                for (let Form of this.forms){
                    this.init(Form);
                }
            }
        }
    }

    init (Form) {
        let FormItem =  new FPWDValidateForm(Form, this.settings, this.MESSAGES, this.REGEXP);
        FormItem.on('submit', (data) => {
            this.emit('submit', data);
        }).on('validationSuccess', (data) => {
            this.emit('validationSuccess', data);
        }).on('validationError', (data) => {
            this.emit('validationError', data);
        });

        this.items.push(FormItem);
    }

    // Listener
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    // Emiter
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

class FPWDValidateForm {
    constructor (Form, Settings, MESSAGES, REGEXP) {
        this.form = Form;
        this.settings = Settings;
        this.MESSAGES = MESSAGES;
        this.REGEXP = REGEXP;
        this.dispatchHandlers = {};

        if (this.form){
            this.init();
        }
    }

    init() {
        this.form.setAttribute('novalidate', 'novalidate');

        this.state = {
            errors: {},
            values: {},
            formSubmitted: false
        };

        this.getItems();
        this.events();
    }

    getItems() {
        this.items = this.form.querySelectorAll(`${this.settings.validationFieldSelector}:not(:disabled)`);
        this.allItems = this.form.querySelectorAll(`${this.settings.validationFieldSelector}`);
    }

    events() {
        this.form.addEventListener('submit', this.onSumbit.bind(this));
        for (let Item of this.allItems){
            Item.addEventListener('focus', (event) => {
                Item.classList.add('item-touched');
            });
            Item.addEventListener('change', (event) => {
                let parent = this.settings.addErrorToParent ? this.getParent(Item) : Item.parentNode;
                let items = [Item];

                if (this.state.formSubmitted){
                    items = parent.querySelectorAll(`${this.settings.validationFieldSelector}:not(:disabled)`);
                } else {
                    items = parent.querySelectorAll(`.item-touched${this.settings.validationFieldSelector}:not(:disabled)`);
                }

                for (let item of items){
                    this.onChange(item);
                }
            });

            Item.addEventListener('checkedAllChange', (event) => {
                let parent = this.settings.addErrorToParent ? this.getParent(Item) : Item.parentNode;
                let items = [Item];

                if (this.state.formSubmitted){
                    items = parent.querySelectorAll(`${this.settings.validationFieldSelector}:not(:disabled)`);
                } else {
                    items = parent.querySelectorAll(`.item-touched${this.settings.validationFieldSelector}:not(:disabled)`);
                }

                for (let item of items){
                    this.onChange(item);
                }
            });
        }
    }

    /** ON FORM SUBMIT */
    onSumbit(event) {
        this.getItems();
        
        this.state = {
            errors: {},
            values: {},
            formSubmitted: true
        };

        if (this.settings.dynamic){
            event.preventDefault();
        }

        this.emit('submit', { 
            Validator: this, 
            form: this.form, 
            event: event
        });

        this.validateAll();
        if (Object.keys(this.state.errors).length === 0){
            this.getValues();
            this.emit('validationSuccess', { 
                Validator: this, 
                form: this.form, 
                event: event, 
                values: this.state.values 
            });
        } else {
            event.preventDefault();
            this.emit('validationError', { 
                Validator: this, 
                form: this.form, 
                event: event, 
                errors: this.state.errors 
            });
        }
    }

    /** ON ITEM CHANGE */
    onChange(item) {
        this.validateItem(item);
        item.classList.add('item-touched');
    }

    isValid() {
        return Object.keys(this.state.errors).length === 0;
    }

    /** RUN VALIDATION FOR ALL ITEMS */
    validateAll() {
        for (let Item of this.items){
            this.validateItem(Item);
        }
    }

    /** RUN VALIDATION FOR ITEM */
    validateItem(Item) {
        let parent = this.getParent(Item); // for woocommerce
        let required = true;

        /** Check required */
        if (Item.hasAttribute('required') || (parent && parent.classList.contains('validate-required'))){
            required = this.checkValidation(Item, 'required');
        }

        /** Check email */
        if (required && Item.getAttribute('type') === 'email'){
            required = this.checkValidation(Item, 'email');
        }

        /** Check min length */
        if (required && Item.hasAttribute('minlength')){
            required = this.checkValidation(Item, 'minlength');
        }

        /** Check max length */
        if (required && Item.hasAttribute('maxlength')){
            required = this.checkValidation(Item, 'maxlength');
        }

        /** Another validations */
        if (Item.hasAttribute(this.settings.validationAttr)){
            let validations = Item.getAttribute(this.settings.validationAttr);
            if (required && validations.trim() !== ''){
                validations = validations.split(' ');
                for (let validation of validations){
                    this.checkValidation(Item, validation);
                }
            }
        }

        this.removeItemErrors(Item);
        this.addItemErrors(Item);
    }

    /** REMOVE ERRORS FROM ITEM */
    removeItemErrors(Item) {
        let parent = this.settings.addErrorToParent ? this.getParent(Item) : Item.parentNode;

        if (parent === null){
            parent = Item.parentNode;
        }

        let inputs = parent.querySelectorAll(`.${this.settings.errorInputClassName}[name="${Item.name}"]`);
        let errors = parent.querySelectorAll(`.${this.settings.errorClassName}[data-error-for="${Item.name}"]`);

        parent.classList.remove(`${this.settings.errorInputClassName}-parent`);
        
        for (let input of inputs){
            input.classList.remove(this.settings.errorInputClassName);
        }

        if (errors.length > 0){
            for (let error of errors){
                error.parentNode.removeChild(error);
            }
        }
    }

    /** ADD ERRORS TO ITEM */
    addItemErrors(Item) {
        let parent = this.settings.addErrorToParent ? this.getParent(Item) : Item.parentNode;

        if (parent === null){
            parent = Item.parentNode;
        }

        if (Item.name in this.state.errors && parent){ 
            let inputs = this.form.querySelectorAll(`${this.settings.validationFieldSelector}[name="${Item.name}"]`);
            parent.classList.add(`${this.settings.errorInputClassName}-parent`);

            for (let input of inputs){
                input.classList.add(this.settings.errorInputClassName);
            }

            for (let Type in this.state.errors[Item.name]){
                if (parent.querySelector(`.${this.settings.errorClassName}[data-error-type="${Type}"]`) === null){
                    let error = document.createElement('div');
                    error.className = this.settings.errorClassName;
                    error.setAttribute('data-error-for', Item.name);
                    error.setAttribute('data-error-type', Type);

                    if (typeof this.MESSAGES[Type] === "undefined"){
                        error.innerHTML = `Message for "${Type}" is not defined`;
                    } else {
                        error.innerHTML = this.state.errors[Item.name][Type].message;
                    }
                    
                    parent.appendChild(error);
                }
            }
        }
    }

    /** CHECK INPUT VALIDATION TYPES */
    checkValidation(Item, validation) {
        if (typeof this.checkValue()[validation] === "undefined"){
            return;
        }

        if (!this.checkValue()[validation](Item)){
            let value =  Item.hasAttribute(validation) ? Item.getAttribute(validation) : '';
            this.state.errors = Object.assign({}, this.state.errors, {
                [Item.name]: {
                    [validation]: {
                        message: this.MESSAGES[validation].replace(/:value/g, value),
                        element: Item,
                        [validation]: value
                    }
                }
            });
            return false;
        } else {
            if (!(Item.name in this.state.errors)){
                return true;
            }

            if (validation in this.state.errors[Item.name]){
                delete this.state.errors[Item.name][validation];
            } 

            if (Object.keys(this.state.errors[Item.name]).length === 0) {
                delete this.state.errors[Item.name];
            }

            return true;
        }
    }

    /** CHECK INPUTS VALUE BY VALIDATION TYPE */
    checkValue() {
        return {
            required: (Item) => {
                switch (Item.type){
                    case 'radio':
                        let items = this.form.querySelectorAll(`${this.settings.validationFieldSelector}[name="${Item.name}"]`);
                        for (let item of items){
                            if (item.checked){
                                return true;
                            }
                        }
                        return false;
                        break;
                    case 'checkbox':
                        return Item.checked;
                        break;
                    default:
                        return Item.value.trim() !== '';
                }
            },
            minlength: (Item) => {
                let length = parseInt(Item.getAttribute('minlength'));
                return Item.value.trim().length >= length;
            },
            maxlength: (Item) => {
                let length = parseInt(Item.getAttribute('maxlength'));
                return Item.value.trim().length <= length;
            },
            email: (Item) => {
                return this.REGEXP.email.test(Item.value);
            },
            phone: (Item) => {
                return Item.value.trim() === '' || this.REGEXP.phone.test(Item.value);
            },
            zip: (Item) => {
                return this.REGEXP.zip.test(Item.value);
            },
            date: (Item) => {
                return this.REGEXP.date.test(Item.value);
            },
            cardNumber: (Item) => {
                return Item.value.replace(/ /g,'').length === 16 && validationCreditCard(Item.value);
            },
            day: (Item) => {
                let value = Item.value;
                return this.REGEXP.number.test(value) && parseInt(value) > 0 && parseInt(value) <= 29;
            },
            month: (Item) => {
                let value = Item.value;
                return this.REGEXP.number.test(value) && parseInt(value) > 0 && parseInt(value) <= 12;
            },
            price: (Item) => {
                return Item.value.trim() === '' || parseInt(Item.value) > 0;
            },
            cardExpy: (Item) => {
                let filedName;
                let filed;
                let dateObject = {
                    month: '',
                    year: ''
                };

                if (Item.hasAttribute(this.settings.validationExpyMonthAttr)){
                    filedName = Item.getAttribute(this.settings.validationExpyMonthAttr);
                    filed = this.form.querySelector(`[name="${filedName}"]`);
                    dateObject.month = filed.value;
                    dateObject.year = Item.value;
                } else if (Item.hasAttribute(this.settings.validationExpyYearAttr)){
                    filedName = Item.getAttribute(this.settings.validationExpyYearAttr);
                    filed = this.form.querySelector(`[name="${filedName}"]`);
                    dateObject.year = filed.value;
                    dateObject.month = Item.value;
                }

                let now = new Date();
                let prefix = now.getFullYear().toString().substr(0,2);
                let date = new Date(`${prefix + dateObject.year}`, dateObject.month, 0);
                
                if (date.toString() !== "Invalid Date"){
                    return Date.parse(now) <= Date.parse(date);
                }

                return true;
            },
        }
    }

    getValues() {
        let serialize = {};
        
        for (let Item of this.form.elements) {
            if (
                Item instanceof Element && Item.name !== '' && 
                !Item.disabled &&
                (!Item.hasAttribute('data-serialize') || Item.getAttribute('data-serialize') === 'true')
            ){
                switch (Item.type){
                    case 'radio':
                        let items = this.form.querySelectorAll(`[name="${Item.name}"]`);
                        for (let item of items){
                            if (item.checked){
                                serialize[Item.name] = item.value;
                            }
                        }
                        break;
                    case 'checkbox':
                        serialize[Item.name] = Item.checked;
                        break;
                    default:
                        serialize[Item.name] = Item.value; 
                }
            }
        }

        this.state.values = serialize;
    }

    /** GET PARENT */
    getParent(Item, Selector = this.settings.addErrorToParent) {
        if (Item){
            for ( ; Item && Item !== document; Item = Item.parentNode ) {
                if (Item.matches(Selector)) return Item;
            }
            return null;
        } else {
            return null;
        }
    }

    // Listener
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    // Emiter
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

export default FPWDValidate;