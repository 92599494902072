import IMask from 'imask';

/** CONFIG */
import MASK from '../Config/Mask';
import { triggerEvent } from '../Helpers/Element';

const RoundButtonsDefault = {
    radioSelector: '.js--round-buttons-radio',
    inputSelector: '.js--round-buttons-input',
    inputValueSelector: '.js--round-buttons-value'
};

class RoundButtons {
    constructor (WrapperSelector = '.js--round-buttons', Settings = {}) {
        this.wrappers = document.querySelectorAll(WrapperSelector);
        this.settings = Object.assign({}, RoundButtonsDefault, Settings);
        this.dispatchHandlers = {};
        this.items = [];

        if (this.wrappers.length > 0){
            for (let Wrapper of this.wrappers){
                const Item = new RoundButtonsItem(Wrapper, this.settings);
                Item.on('select', (data) => {
                    this.emit('select', data);
                });
                this.items.push(Item);
            }
        }
    }

    // Listener
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    // Emiter
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

class RoundButtonsItem {
    constructor(Wrapper, Settings){
        this.wrapper = Wrapper;
        this.settings = Settings;
        this.dispatchHandlers = {};0
        
        this.radioList = this.wrapper.querySelectorAll(this.settings.radioSelector);
        this.input = this.wrapper.querySelector(this.settings.inputSelector);
        this.inputValueField = this.wrapper.querySelector(this.settings.inputValueSelector);
        this.type = this.wrapper.getAttribute('data-type');

        if (this.input){
            this.inputMast();
        }

        if (this.radioList.length > 0){
            this.radioEvent();
        }
    }

    /** ADD EVENT LISTENT FOR RADIO LIST */
    radioEvent(){
        for (let Radio of this.radioList){
            Radio.addEventListener('change', this.onRadioSelect.bind(this));
        }
    }

    /** INIT INPUT MANUAL VALUE MASK AND ADD EVENT LISTENT */
    inputMast() {
        this.inputMask = new IMask(this.input, MASK.price);

        this.inputMask.on("accept", () => {
            this.emit('select', { RoundButtons: this, value: parseInt(this.inputMask.value) });
            this.onInputSelect();
            this.setInputValue(this.inputMask.value);
        });
    }

    /** CLEAN INPUT MANUAL VALUE ON RADIO SELECT */
    onRadioSelect(event) {
        if (this.inputMask){
            this.inputMask.value = '';
        }
        event.target.checked = true;
        this.setInputValue(event.target.value);                   
        this.emit('select', { RoundButtons: this, value: parseInt(event.target.value) });
    }

    /** ONCHECKED ALL RADIO ON INPUT MANUAL VALUE */
    onInputSelect() {
        for (let Radio of this.radioList){
            Radio.checked = false;
        }
    }

    /** SET AMOUNT INPUT */
    setInputValue(value) {
        if (this.inputValueField){
            this.inputValueField.value = value;
            this.inputValueField.classList.add('item-touched');
            triggerEvent(this.inputValueField, 'change');
        }
    }

    // Listener
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    // Emiter
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

export default RoundButtons;