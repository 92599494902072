import { scrollPage } from '../../src/js/Helpers/Scroll';

import './FPWDAccordion.scss';

const FPWDAccordionDefault = {
    dynamic: false,
    dynamicLoadedClassName: 'js--is-loaded',
    itemSelector: '.js--accordion-item',
    headSelector: '.js--accordion-head',
    bodySelector: '.js--accordion-body',
    contentSelector: '.js--accordion-content',
    transition: 'height 0.2s ease-out',
    closeSibling: true,
    scrollToHead: false
};

class FPWDAccordion {
    constructor (WrapperSelector = '.js--accordion', Settings = {}){
        this.elements = document.querySelectorAll(WrapperSelector);
        this.settings = Object.assign({}, FPWDAccordionDefault, Settings);
        this.items = [];
        this.dispatchHandlers = [];

        if (this.elements.length > 0){
            this.init();
        }
    }

    init () {
        for (let element of this.elements){
            let accordion = new FPWDAccordionItem(element, this.settings);
            accordion
                .on('hide', (data) => this.emit('hide', data))
                .on('show', (data) => this.emit('show', data))
                .on('dynamic', (data) => this.emit('dynamic', data))

            this.items.push(accordion);
        }
    }

    // Listener
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    // Emiter
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

class FPWDAccordionItem {
    constructor(wrapper, settings) {
        this.wrapper = wrapper;
        this.settings = settings;
        this.items = this.wrapper.querySelectorAll(this.settings.itemSelector);
        this.activeItem = null;
        this.dispatchHandlers = [];

        if (this.items.length > 0){
            this.events();
        }
    }

    events () {
        for (let item of this.items){
            let head = item.querySelector(this.settings.headSelector);
            let body = item.querySelector(this.settings.bodySelector);

            body.style.transition = this.settings.transition;

            head.addEventListener('click', (event) => {
                event.preventDefault();

                if (item.classList.contains('active')){
                    this.hide(item);
                } else {
                    if (this.settings.closeSibling){
                        this.hideAll();
                    }
                    this.checkShow(item);
                }
            });
        }

        window.addEventListener('resize', this.resize.bind(this));
    }

    /** HIDE ALL BODIES */
    hideAll() {
        for (let item of this.items){
            this.hide(item);
        }
    }

    /** HIDE BODY */
    hide (item) {
        let body = item.querySelector(this.settings.bodySelector);
        item.classList.remove('active');
        body.style.height = null;
        this.activeItem = null;
        this.emit('hide', { Accordion: this, Item: item });
    }

    /** BEFORE SHOW EVENT */
    checkShow (item) {
        if (this.settings.dynamic && !this.isLoaded(item)) {
            this.emit('dynamic', { Accordion: this, Item: item });
        } else {
            this.show(item);
        }
    }

    /** SHOW BODY */
    show (item) {
        let body = item.querySelector(this.settings.bodySelector);
        item.classList.add('active');
        body.style.height = `${body.querySelector(this.settings.contentSelector).scrollHeight}px`;
        this.activeItem = item;
        this.emit('show', { Accordion: this, Item: item });

        if (this.settings.scrollToHead) {
            setTimeout(() => {
                scrollPage(item, 400, 'linear');
            }, 300);
        }
    }

    /** RESIZE ITEM BODY */
    resize() {
        if (this.activeItem !== null){
            let body = this.activeItem.querySelector(this.settings.bodySelector);
            body.style.height = `${body.querySelector(this.settings.contentSelector).scrollHeight}px`;
        }
    }

    /** CHECK IF IS LOADED */
    isLoaded (item) {
        return item.classList.contains(this.settings.dynamicLoadedClassName);
    }

    /** ON DYNAMIC LOAD */
    onLoad(item) {
        item.classList.add(this.settings.dynamicLoadedClassName);
    }

    // Listener
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    // Emiter
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

export default FPWDAccordion;