const userDataValuesFields = [
    'name',
    'surname',
    'email',
    'phone_prefix',
    'phone',
    'firstname',
    'lastname',
    'street',
    'number',
    'zipcode',
    'city',
    'province',
];

export function GetUserData() {
    let usetData = localStorage.getItem('userData');

    if (usetData) {
        return JSON.parse(usetData);
    }

    return {};
}

export function AutocompleteForm(form = null) {
    if (form) {
        const userData = GetUserData();

        for (let field in userData) {
            const fieldElements = form.querySelectorAll(`[name="${field}"]`);
        
            for (let element of fieldElements) {
                element.value = userData[field];
            }
        }
    }
}

export function SaveUserData(values) {
    let userData = {};

    for (let field in values) {
        if (userDataValuesFields.indexOf(field) >= 0) {
            userData[field] = values[field];
        }
    }

    userData = Object.assign({}, GetUserData(), userData);
    localStorage.setItem('userData', JSON.stringify(userData));
}