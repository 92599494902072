import FPWDModal from '../../../vendor/FPWDModal/FPWDModal';

const VideoDefault = {
    playSelector: '.js--video-play',
    videoSelector: '.js-video-element',
    videoLazyAttr: 'data-src',
    videoTypeAttr: 'data-video-type',
    videoLinkSrcAttr: 'data-video-link',
    pauseTimeout: 3000,
    isInitClassName: 'is-init'
};

class Video {
    constructor(BlockSelector = '.js--video', Settings = {}) {
        this.settings = Object.assign({}, VideoDefault, Settings);
        this.blocks = document.querySelectorAll(`${BlockSelector}:not(.${this.settings.isInitClassName})`);
        this.videos = [];

        if (this.blocks.length > 0){
            for (let Block of this.blocks){
                let type = Block.getAttribute(this.settings.videoTypeAttr);
                this.videos.push((type === 'upload' ? new VideoUploadItem(Block, this.settings) : new VideoYouTubeItem(Block, this.settings)));
            }
        }
    }
}

class VideoYouTubeItem{
    constructor (Block, Settings) {
        this.block = Block;
        this.settings = Settings;

        this.video = this.block.querySelector(this.settings.videoSelector);
        this.play = this.block.querySelector(this.settings.playSelector);
        this.link = this.video.getAttribute(this.settings.videoLinkSrcAttr);

        if (this.video && this.link && this.play){
            this.init();
            this.block.classList.add(this.settings.isInitClassName);
        }
    }

    init () {
        this.videoModal = new FPWDModal(this.play, {
            body: `
                <div class="block-video-iframe">
                    <iframe 
                        src="${this.link}?rel=0&amp;autoplay=1" 
                        frameborder="0" 
                        allow='autoplay'
                        allowfullscreen></iframe>
                </div>`
        });
    }
}

class VideoUploadItem{
    constructor (Block, Settings) {
        this.block = Block;
        this.settings = Settings;
        this.loaded = false;

        this.video = this.block.querySelector(this.settings.videoSelector);
        this.sources = this.video.getElementsByTagName('source');
        this.play = this.block.querySelector(this.settings.playSelector);
        this.timer = null;

        if (this.video && this.sources.length > 0 && this.play){
            this.events();
            this.block.classList.add(this.settings.isInitClassName);
        }
    }

    /** EVENT */
    events () {
        this.play.addEventListener('click', this.checkLoaded.bind(this));
        this.video.addEventListener('loadeddata', this.onVideoLoaded.bind(this));
        this.video.addEventListener('play', this.onPlayVideo.bind(this));
        this.video.addEventListener('pause', this.onPauseVideo.bind(this));
        this.video.addEventListener('ended', this.stopVideo.bind(this));
    }

    /** CHECK IF VIDEO IS LOADED */
    checkLoaded () {
        if (this.loaded){
            this.playVideo();
        } else {
            this.loadVideo();
        }
    }

    /** LOAD VIDEO */
    loadVideo() {
        for (let source of this.sources){
            if (source.hasAttribute(this.settings.videoLazyAttr)){
                source.src = source.getAttribute(this.settings.videoLazyAttr);
            }
        }
        this.video.load();
    }

    /** PLAY VIDEO */
    playVideo() {
        this.resetTimer();
        this.block.classList.add('video-is-visible');
        setTimeout(() => {
            this.video.play();
        }, 500);
    }

    /** STOP AND HIDE VIDEO */
    stopVideo() {
        this.resetTimer();

        if (document.fullscreenElement && document.exitFullscreen){
            document.exitFullscreen();
        } else if (document.webkitFullscreenElement && document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozFullScreenElement && document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msFullScreenElement && document.msExitFullscreen) {
            document.msExitFullscreen();
        }

        setTimeout(() => {
            this.block.classList.remove('video-is-visible');
        }, 500);
    }

    /** ON VIDEO LOADED */
    onVideoLoaded(){
        this.loaded = true;
        this.playVideo();
    }

    /** ON VIDEO PLAY */
    onPlayVideo() {
        this.resetTimer();
    }

    /** ON VIDEO PAUSE */
    onPauseVideo() {
        this.timer = setTimeout(this.stopVideo.bind(this), this.settings.pauseTimeout);
    }

    resetTimer() {
        if (this.timer !== null){
            clearInterval(this.timer);
        }
        this.timer = null;
    }
}

export default Video;