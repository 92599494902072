/** VENDOR */
import IMask from 'imask';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday'
import FPWDValidate from '../../../vendor/FPWDValidate/FPWDValidate';
import FPWDFileUpload from '../../../vendor/FPWDFileUpload/FPWDFileUpload';
import FPWDPayment from '../../../vendor/FPWDPayments/FPWDPayment';

/** CONFIG */
import MASK from '../Config/Mask';

/** HELPERS */
import { scrollPage } from "../Helpers/Scroll";
import { ajax } from '../Helpers/Ajax';

/** COMPONENTS */
import { BoxToast, BoxToastError, BoxInfo } from '../Components/Box';
import RoundButtons from '../Components/RoundButtons';
import { AddButtonLoader, RemoveButtonLoader } from '../Components/Button';
import { onRequestError } from '../Helpers/Request';
import { getElementParent } from '../Helpers/Element';
import ROUTING from '../Config/Routing';
import { SaveUserData, AutocompleteForm } from '../Helpers/SaveUserData';
import CheckboxGroup from '../Components/CheckboxGroup';
import ENV from '../Config/Env';


const PaymentFormDefault = {
    // Frequency
    setFrequencySelector: '.js--set-frequency-type',
    frequencyAttr: 'data-frequency',

    // Payments
    paymentSectionSelector: '.js--payment-section',
    setPaymentTypeSelector: '.js--set-payment-type',
    paymentTypeAttr: 'data-payment-type',
    paymentDaysOfWeekMessage: '.js--payment-days-of-week-message',

    // Upload
    fileUploadSelector: '.js--file-upload',

    // Message
    formMessageSelector: '.js--form-message',

    // Submit button
    submitButtonSelector: '.js--form-submit',

    inputMaskAttr: 'data-input-mask',
    inputMaskCustomAttr: 'data-mask-pattern'
};

dayjs.extend(weekday);

class PaymentForm {
    constructor (FormSelector = '.js--payment-form') {
        this.form = document.querySelector(FormSelector);
        this.paymentService = null;
        this.uploader = null;
        this.settings = PaymentFormDefault;
        this.formData = {};
        this.formDataFiles = {};
        this.masks = [];

        if (this.form){
            this.init();
        }
    }

    init () {
        AutocompleteForm(this.form);
        // Frequency
        this.setFrequencyInputs = this.form.querySelectorAll(this.settings.setFrequencySelector);
        this.frequencyBoxes = this.form.querySelectorAll(`[${this.settings.frequencyAttr}]`);

        // Amount
        this.roundButtons = new RoundButtons();
        this.roundButtons.on('select', ({ RoundButtons, value }) => {
            if (RoundButtons.type === 'payment_every_week') {
                this.setPaymentDaysOfWeekMessage(value);
            }
        });

        // Payment
        this.paymentSection = this.form.querySelector(this.settings.paymentSectionSelector);
        this.setPaymentTypeInputs = this.form.querySelectorAll(this.settings.setPaymentTypeSelector);
        this.paymentTypeBoxes = this.form.querySelectorAll(`[${this.settings.paymentTypeAttr}]`);
        this.hasPayuParams = typeof __jsVars !== "undefined" && typeof __jsVars.payu !== "undefined";
        this.paymentDaysOfWeekMessage = this.form.querySelector(this.settings.paymentDaysOfWeekMessage);

        // Form message
        this.formMessage = this.form.querySelector(this.settings.formMessageSelector);

        // Submit
        this.submit = this.form.querySelector(this.settings.submitButtonSelector);

        // Form action
        this.formAction = this.form.getAttribute('action');

        if (this.paymentSection && this.hasPayuParams){
            this.initPaymentService();
        }

        // Check all
        this.checkAll = new CheckboxGroup();

        // Input album name
        this.inputAlbumName = this.form.querySelector('.js--name-in-album');
        this.acceptanceAlbum = this.form.querySelector('.js--acceptance-album');
        this.phonePrefix = this.form.querySelector('.js--phone-prefix');

        this.initState();
        this.events();
        this.validateForm();
        this.initInputMask();
        this.initUploader();
    }

    events () {
        // Set frequency events
        for (let Input of this.setFrequencyInputs){
            Input.addEventListener('change', this.onChangeFrequency.bind(this));
        }

        // Set payment type events
        for (let Input of this.setPaymentTypeInputs){
            Input.addEventListener('change', this.onChangePaymentType.bind(this));
        }

        if (this.inputAlbumName) {
            this.inputAlbumName.addEventListener('change', this.setAcceptanceAlbum.bind(this));
        }
        if (this.phonePrefix) {
            let phonePrefixOptions = this.phonePrefix.querySelectorAll('option');
            this.phonePrefix.addEventListener('focusin', e => {
                phonePrefixOptions.forEach(element => {
                    element.label = element.dataset.text;
                });
            });
            this.phonePrefix.addEventListener('change', e => {
                phonePrefixOptions.forEach(element => {
                    element.label = element.value;
                });
                this.phonePrefix.blur();
            });
        }
    }



    /**
     * On change frequency
     * @param {Event} event
     */
    onChangeFrequency(event) {
        let type = event.target.value;
        this.setState({
            frequency: type,
            paymentType: type === 'every_week' || type === 'every_month' ? 'card' : this.state.paymentType
        });
    }


    /**
     * On change payment type
     * @param {Event} event
     */
    onChangePaymentType(event) {
        this.setState({
            paymentType: event.target.value
        });
    }


    /**
     * Init payment service
     */
    initPaymentService() {
        this.paymentService = new FPWDPayment(["card", "googlepay", "applepay"], {
            formSelector: '.js--payment-form',
            amountFieldSelector: '.js--payment-amount-field',
            tokenFieldSelector: '.js--payment-token-field',
            fingerPrintFieldSelector: '.js--payment-finger-print-field',
        }, {
            card: {
                environment: __jsVars.payu.environment,
                merchantId: __jsVars.payu.merchantId,
            },
            googlepay: {
                environment: __jsVars.payu.google.environment,
                merchantId: __jsVars.payu.google.merchantIdentifier,
                gatewayMerchantId: __jsVars.payu.merchantId,
                buttonPaySelector: '.js--payment-button-googlepay',
                currency: 'PLN',
                allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                allowedCardNetworks: ['MASTERCARD', 'VISA']
            },
            applepay: {
                environment: __jsVars.payu.apple.environment,
                merchantId: __jsVars.payu.apple.merchantIdentifier,
                totalLabel: __jsVars.payu.apple.title,
                getSessionUrl: ROUTING.apple_pay_session_url
            }
        });
        // On token success -> send form
        this.paymentService.on('success', ({ data }) => {
            this.formData._token = data.token;
            this.sendForm();
        });

        this.paymentService.on('error', (error) => {
            RemoveButtonLoader(this.submit);
            this.errorsFactory(error);
        });
    }


    /**
     * Form validation
     */
    validateForm() {
        this.validate = new FPWDValidate(this.form, {
            dynamic: true
        });
        this.validate.on('validationSuccess', ({values}) => {
            if (!this.uploader || this.uploader.getItem(0).checkValid()) {
                this.formData = values;
                if ('price' in this.formData) {
                    this.formData.price = this.parsePriceToFloat(this.formData.price);
                }
                AddButtonLoader(this.submit);
                // Get payment token
                if (["card", "googlepay", "applepay"].includes(this.formData.payment) && this.hasPayuParams){
                    this.paymentService.getToken(this.formData.payment);
                } else {
                    this.sendForm();
                }
            }
        }).on('validationError', ({form, errors}) => {
            let firstError = form.querySelectorAll('.js-validate-error-field-parent');
            if (firstError[0]){
                scrollPage(firstError[0], 400, 'linear');
            }
        });
    }

    // Parse price to float: remove spaces, currency
    parsePriceToFloat(price) {
        price = price.toString().replace(new RegExp(' ', 'g'), '').replace(new RegExp(',', 'g'), '.');
        return parseFloat(price);
    }

    /**
     * Send form
     */
    sendForm() {
        SaveUserData(this.formData);
        ajax({
            url: this.formAction,
            method: 'POST',
            data: Object.assign({}, this.formData, this.formDataFiles),
            formData: true
        })
        .then((response) => this.onFormSended(response))
        .catch((error) => this.onFormSendedError(error));
    }

    /**
     * On form sended
     */
    onFormSended(response) {
        let timeout = 0;

        // if has message -> push message and set timeout for redirect
        if (typeof response.message !== "undefined"){
            BoxToast(response.message, 'success');
            timeout = 3000;
        }

        // if has file uploader -> clean file uploader
        if (this.uploader){
            this.uploader.getItem(0).reset();
        }

        // remove loader from button
        RemoveButtonLoader(this.submit);

        // reset form
        this.form.reset();

        // unset day payment message
        this.unsetPaymentDaysOfWeekMessage();

        // Set payment status (nedd only, if payments use ApplePay type)
        if (this.paymentService && this.paymentService.currentPaymentType) {
            this.paymentService.setPaymentStatus(true);
        }

        this.initState();

        // if has radirect -> redirect after timeout
        if (typeof response.redirect_to !== "undefined"){
            setTimeout(() => {
                window.location.href = response.redirect_to;
            }, timeout);
        }
    }

    /**
     * On form sended error
     */
    onFormSendedError(error) {
        onRequestError(error, this.submit);
        // Set payment status (nedd only, if payments use ApplePay type)
        if (this.paymentService) {
            this.paymentService.setPaymentStatus(false);
        }
    }


    /**
     * Init uploader
     */
    initUploader() {
        if (this.form.querySelector(this.settings.fileUploadSelector)){
            this.uploader = new FPWDFileUpload(this.settings.fileUploadSelector, {
                url: this.action,
                limit: 1,
                required: false
            }, {
                limit: __jsVars.l10n.validation.uploaderLimitOne,
            });

            this.uploader.on('validationError', ({ Uploader, File, Type }) => {
                BoxToastError(Uploader.getErrorMessage(Type, File));
            }).on('appendAll', ({ Files }) => {
                this.formDataFiles = Files;
                this.setAcceptanceAlbum();
            }).on('removeFile', ({ Files }) => {
                this.formDataFiles = Files;
                this.setAcceptanceAlbum();
            });
        }
    }


    /**
     * Init masks for inputs
     */
    initInputMask () {
        this.inputsMask = this.form.querySelectorAll(`[${this.settings.inputMaskAttr}]`);

        for (let Input of this.inputsMask){
            let mask = Input.getAttribute(this.settings.inputMaskAttr);
            let options;

            if (mask === 'custom'){
                options = {
                    mask: Input.getAttribute(this.settings.inputMaskCustomAttr)
                }
            } else {
                options = MASK[mask];
            }

            this.masks.push(IMask(Input, options));
        }
    }


    /**
     * Set paument days of week message
     * @param {number} selectedDay 
     */
    setPaymentDaysOfWeekMessage(selectedDay) {
        if (this.paymentDaysOfWeekMessage) {
            const currentDate = dayjs();
            const currentDay = currentDate.weekday() === 0 ? 7 : currentDate.weekday();
            const daysToAdd = (selectedDay - currentDay) + 7;
            const dateToShow = currentDate.add(daysToAdd, 'day');
            const message = __jsVars.l10n.paymentDateMessage.replace(new RegExp(':dateOfPayment', 'g'), `<b>${dateToShow.format(ENV.dateFormat)}</b>`);
            this.paymentDaysOfWeekMessage.innerHTML = BoxInfo(message);
            this.paymentDaysOfWeekMessage.classList.remove('hidden');
        }
    }

    /**
     * Unset paument days of week message
     */
    unsetPaymentDaysOfWeekMessage() {
        if (this.paymentDaysOfWeekMessage) {
            this.paymentDaysOfWeekMessage.innerHTML = '';
            this.paymentDaysOfWeekMessage.classList.add('hidden');
        }
    }

    /**
     * Set frequency
     */
    setFrequency() {
        // Set visibility boxes for frequency type
        for (let Box of this.frequencyBoxes){
            let Types = Box.getAttribute(this.settings.frequencyAttr).split('|');
            if (Types.includes(this.state.frequency) ){
                if( this.state.paymentType!=='transfer' ){
                    this.unsetDisabled(Box);
                    Box.classList.remove('hidden');
                    //console.log( this.state.frequency );
                }
            } else {
                Box.classList.add('hidden');
                this.setDisabled(Box);
            }
        }
    }


    /**
     * Set payment type
     */
    setPaymentType() {
        // Set active input
        const inputChecked = this.form.querySelector(`${this.settings.setPaymentTypeSelector}:checked`);
        if (inputChecked && inputChecked.value !== this.state.paymentType){
            const input = this.form.querySelector(`${this.settings.setPaymentTypeSelector}[value="${this.state.paymentType}"]`);
            if (input){
                input.checked = true;
            }
        }

        if (this.state.paymentType === 'transfer') {
            if (this.formMessage) {
                this.formMessage.classList.add('hidden');
            }
            if (this.submit) {
                getElementParent(this.submit, 'div').classList.add('payment-form-message');
                this.submit.innerHTML = __jsVars.l10n.buttons.activeTransferPayment;
            }
        } else {
            if (this.formMessage) {
                this.formMessage.classList.remove('hidden');
            }
            if (this.submit) {
                getElementParent(this.submit, 'div').classList.remove('payment-form-message');
                if( this.submit.classList.contains('js--form-submit-spiritual') ){
                    this.submit.innerHTML = __jsVars.l10n.buttons.activeAdoptionSubmit;
                }else{
                    this.submit.innerHTML = __jsVars.l10n.buttons.activeFastPayment;
                }
            }
        }

        // Set visibility boxes for payment type
        for (let Box of this.paymentTypeBoxes){
            const Types = Box.getAttribute(this.settings.paymentTypeAttr).split('|');
            if (Types.includes(this.state.paymentType)){
                this.unsetDisabled(Box);
                Box.classList.remove('hidden');
            } else {
                Box.classList.add('hidden');
                this.setDisabled(Box);
            }
        }
    }

    /**
     * Set disablet for form elements of box
     * @param {Element} Box
     */
    setDisabled(Box) {
        let items = Box.querySelectorAll('input, textarea, select');
        for (let item of items){
            item.disabled = true;
        }
    }

    /**
     * Unset disablet for form elements of box
     * @param {Element} Box
     */
    unsetDisabled(Box) {
        let items = Box.querySelectorAll('input, textarea, select');
        for (let item of items){
            item.disabled = false;
        }
    }


    /**
     * Acceptance for album
     */
    setAcceptanceAlbum () {
        if (!this.acceptanceAlbum) {
            return false;
        }

        if (this.inputAlbumName && this.inputAlbumName.value !== '' || Object.keys(this.formDataFiles).length > 0) {
            this.acceptanceAlbum.setAttribute('required', 'required');
        } else {
            this.validate.items[0].removeItemErrors(this.acceptanceAlbum);
            this.acceptanceAlbum.removeAttribute('required');
        }

        this.validate.items[0].getItems();
    }


    /**
     * Errors factory
     * @param {Object} param0
     */
    errorsFactory({ error }) {
        console.error(error);

        if (typeof error.status === "undefined" || typeof error.status.code === "undefined"){
            return false;
        }

        let message = false;

        if (error.status.code in __jsVars.l10n.errors){
            message = __jsVars.l10n.errors[error.status.code];
        } else {
            message = __jsVars.l10n.errors['PAYU_ERROR_CARD'];
        }

        if (message){
            BoxToast(message, 'error')
        }
    }

    /**
     * Init state
     */
    initState() {
        let activeFrequency = this.form.querySelector(`${this.settings.setFrequencySelector}`);
        const activePayment = this.form.querySelector(`${this.settings.setPaymentTypeSelector}:checked`);

        if (activeFrequency && activeFrequency.tagName.toLowerCase() === 'input' && activeFrequency.type === 'radio'){
            activeFrequency = this.form.querySelector(`${this.settings.setFrequencySelector}:checked`);
        }

        this.state = {
            frequency: activeFrequency ? activeFrequency.value : null,
            paymentType: activePayment ? activePayment.value : null,
        };

        this.setPaymentType();
        this.setFrequency();
        window.dispatchEvent(new window.CustomEvent('updateApprovals'));
    }

    /**
     * Set state
     * @param {Object} newState
     */
    setState(newState) {
        this.state = Object.assign({}, this.state, newState);

        this.setPaymentType();
        this.setFrequency();
        window.dispatchEvent(new window.CustomEvent('updateApprovals'));
    }
}

export default PaymentForm;