import { AddButtonLoader, RemoveButtonLoader } from "../Components/Button";
import { ajax } from "../Helpers/Ajax";

const SearchDefault = {
    itemsSelector: '.js--search-items',
    itemSelector: '.js--search-item',
    btnLoadMoreSelector: '.js--btn-load-more'
};


class Search {
    constructor(SectionSelector = '.js--search-default'){
        this.section = document.querySelector(SectionSelector);
        this.settings = SearchDefault;

        if (this.section){
            this.searchResult = document.createElement('div');
            this.itemsBox = this.section.querySelector(this.settings.itemsSelector);
            this.buttonLoadMore = this.section.querySelector(this.settings.btnLoadMoreSelector);

            this.events();
        }
    }

    /** EVENTS */
    events(){
        if (this.buttonLoadMore){
            this.buttonLoadMore.addEventListener('click', (event) => {
                event.preventDefault();
                this.getItems();
            });
        }
    }

    /** UPDATE ITEMS */
    getItems() {
        AddButtonLoader(this.buttonLoadMore);

        ajax({
            url: this.buttonLoadMore.getAttribute('href'),
            method: 'GET'
        }, {
            'X-Requested-With': 'XMLHttpRequest'
        }).then((response) => {
            this.searchResult.innerHTML = response;
            this.addResult();
            RemoveButtonLoader(this.buttonLoadMore);
        }).catch((error) => {
            RemoveButtonLoader(this.buttonLoadMore);
        });
    }

    /** ADD SEARCH RESULT */
    addResult() {
        const items = this.searchResult.querySelectorAll(this.settings.itemSelector);
        const button = this.searchResult.querySelector(this.settings.btnLoadMoreSelector);
    
        for (let item of items){
            this.itemsBox.appendChild(item);
        }

        if (button){
            this.buttonLoadMore.setAttribute('href', button.getAttribute('href'));
            this.buttonLoadMore.innerHTML = button.innerHTML;
        } else {
            const parent = this.buttonLoadMore.parentNode;
            parent.parentNode.removeChild(parent)
        }
    }
}

export default Search;