/** VENDOR */
import '../../../vendor/Swiper/Swiper';
import ENV from '../Config/Env';

const SliderBoxDefault = {
    slidesPerView: 'auto',
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
        renderFraction: (currentClass, totalClass) => {
            return `
                <span class="${currentClass}"></span>
                <span class="swiper-pagination-separator">/</span>
                <span class="${totalClass}"></span>
            `;
        }
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
};

class SliderBox {
    constructor (ElementSelector = '.swiper-container', CustomSettings = {}) {
        this.elements = document.querySelectorAll(ElementSelector);
        this.customSettings = CustomSettings;
        this.sliders = [];
        this.init();
    }

    init () {
        if (this.elements.length > 0){
            for (let element of this.elements){
                this.sliders.push(new SliderBoxItem(element, Object.assign({}, SliderBoxDefault, this.customSettings)));
            }
        }
    }
}

class SliderBoxItem {
    constructor (element, settings){
        this.element = element;
        this.settings = settings;
        this.slider = null;
        
        if (typeof this.element !== 'undefined'){
            this.initSlider();
        }
    }

    initSlider() {
        if (this.slider === null){
            this.sliderItems = this.element.querySelector('.swiper-wrapper').innerHTML;
            const itemsLength = this.element.querySelectorAll('.swiper-slide').length;
            const isDesktop = window.innerWidth >= ENV.breakpoints.lg;
            const options = {
                ...this.settings, 
                init: false, 
                pagination: {
                    ...this.settings.pagination,
                    type: !isDesktop && itemsLength > 7 ? 'fraction' : 'bullets'
                }
            };
            
            this.slider = new Swiper(this.element, options);
        
            this.slider.on('init', () => {
                window.performanceLoad.researchItems();
                window.performanceLoad.runProcessing();
            });
            this.slider.on('resize', () => {
                window.performanceLoad.researchItems();
                window.performanceLoad.runProcessing();
            });

            this.slider.init();
        }
    }
}

export default SliderBox;