/** COMPONENTS */
import Filter from "../Components/Filter";
import { AddButtonLoader, RemoveButtonLoader } from "../Components/Button";
import { ajax } from "../Helpers/Ajax";
import ROUTING from "../Config/Routing";
import InfiniteScroll from "../Helpers/InfiniteScroll";
import { BoxLoading, BoxToastError, BoxError } from "../Components/Box";

const CatalogDefault = {
    apiUrl: '',
    itemsSelector: '.js--catalog-items',
    itemSelector: '.js--catalog-item',
    filtersSelector: '.js--filter-wrap',
    btnLoadMoreSelector: '.js--btn-load-more',
    postsPerPageAttr: 'data-posts_per_page',
    maxNumPagesAttr: 'data-max_num_pages',
    categoryAttr: 'data-category'
};

class Catalog {
    constructor (SectionSelector = '.js--catalog-default', Settings = {}, ApiParams = {}) {
        this.sections = document.querySelectorAll(SectionSelector);
        this.items = [];

        if (this.sections.length > 0){
            for (let Section of this.sections){
                this.items.push(new CatalogItem(Section, Object.assign({}, CatalogDefault, Settings)));
            }
        }
    }
}

class CatalogItem {
    constructor(Section, Settings){
        this.section = Section;
        this.settings = Settings;
        this.catalog = this.section.querySelector(this.settings.itemsSelector);
        this.boxLoader = document.createElement('div');

        if (this.catalog){
            this.initState();
            this.init();
        }
    }

    /** INIT */
    init () {
        this.buttonLoadMore = this.section.querySelector(this.settings.btnLoadMoreSelector);
        
        if (this.settings.filtersSelector){
            this.filters();
        }

        this.events();
    }

    /**
     * Events
     */
    events(){
        /**
         * If has button load more -> add event click to button
         * Else -> init InfiniteScroll for lazy load elements
         */
        if (this.buttonLoadMore){
            this.buttonLoadMore.addEventListener('click', (event) => {
                event.preventDefault();
                this.goToNextPage();
            });
        } else {
            this.infiniteScroll = new InfiniteScroll(this.section, {
                elementPosition: 'bottom',
                offset: window.innerHeight
            });
            this.infiniteScroll.on('visible', this.goToNextPage.bind(this));
        }
    }

    /**
     * Go to next page
     */
    goToNextPage() {
        if (this.state.request.paged < this.state.pages){
            this.setState({
                loaderType: 'loadMore',
                request: {
                    ...this.state.request,
                    paged: this.state.request.paged + 1
                }
            }).then(this.updateItems());
        }
    }

    /**
     * Init filters
     */
    filters() {
        if (this.section.querySelector(this.settings.filtersSelector)){
            this.filters = new Filter(this.settings.filtersSelector, this.section);
            const Filters = this.filters.getFilters();
   
            for (let Filter of Filters){
                // Set state on filters init
                if (typeof Filter.current !== 'undefined' && typeof Filter.current.value !== "undefined"){
                    this.setState({
                        request: {
                            ...this.state.request,
                            [Filter.name]: Filter.current.value
                        }
                    });
                }
                // Set state on filters select
                Filter.on('select', (data) => {
                    const request = {...this.state.request};

                    if (!Filter.current.value && Filter.name in request) {
                        delete request[Filter.name];
                    } else if (Filter.current.value) {
                        request[Filter.name] = Filter.current.value
                    }

                    this.setState({
                        loaderType: 'filters',
                        request: {
                            ...request,
                            paged: 1,
                        }
                    }).then(this.updateItems());
                });
            }
        }
    }

    /**
     * Update items
     */
    updateItems() {
        if (this.state.isPending){
            return 'isPending';
        }

        if (this.state.isApiError){
            return 'isApiError';
        }

        this.setState({
            isPending: true
        });

        this.addLoader();

        ajax({
            method: "GET",
            url: this.settings.apiUrl,
            data: this.state.request
        })
        .then((response) => { this.onResponseSuccess(response) })
        .catch((error) => { this.onResponseError(error) });
    }

    /**
     * On request success
     * @param {Object} response 
     */
    onResponseSuccess(response) {
        this.setState({
            isPending: false,
            pages: parseInt(response.pages),
            request: {
                ...this.state.request,
                paged: parseInt(response.paged)
            }
        });
        
        this.setButtonVisible();
        this.removeLoader();
        this.setCategoryHTML(response.html);
    }

    /**
     * On request error
     * @param {Object} error 
     */
    onResponseError(error) {
        let message = error.message;

        if (typeof message === 'undefined') {
            message = __jsVars.l10n.errors.default
        }

        this.setState({
            isPending: false,
            isApiError: true
        });
        this.removeLoader();
        this.setCategoryHTML(BoxError(message, true));
        BoxToastError(message);
    }

    /**
     * Add loader
     */
    addLoader() {
        this.boxLoader.className = '';

        if (this.state.loaderType === 'loadMore'){

            if (this.buttonLoadMore){
                AddButtonLoader(this.buttonLoadMore);
            } else {
                this.boxLoader.innerHTML = BoxLoading(true);
                this.section.appendChild(this.boxLoader);
            }

        } else {
            this.boxLoader.className = 'col';
            this.boxLoader.innerHTML = BoxLoading(true);
            this.catalog.innerHTML = '';
            this.catalog.appendChild(this.boxLoader);
        }
    }

    /**
     * Remove loader
     */
    removeLoader() {
        if (this.state.loaderType === 'loadMore'){
            if (this.buttonLoadMore){
                RemoveButtonLoader(this.buttonLoadMore);
            } else {
                if (this.boxLoader) {
                    this.boxLoader.innerHTML = '';
                    this.section.removeChild(this.boxLoader);
                }
            }
        }
    }

    /**
     * Set category HTML
     * @param {String} html 
     */
    setCategoryHTML(html) {
        if (this.state.loaderType === 'loadMore'){
            this.catalog.innerHTML = this.catalog.innerHTML + html;
        } else {
            this.catalog.innerHTML = html;
        }

        window.performanceLoad.researchItems();
        window.performanceLoad.runProcessing();
    }

    /**
     * Set button load more visible
     * If current page === count of pages -> hide button
     * Else -> show button
     */
    setButtonVisible() {
        if (!this.buttonLoadMore){
            return;
        }

        if (this.state.pages === this.state.request.paged){
            this.buttonLoadMore.classList.add('hidden');
        } else {
            this.buttonLoadMore.classList.remove('hidden');
        }
    }

    /**
     * Set state
     * @param {Object} newState 
     */
    setState(newState){
        return new Promise((resolve) => {
            this.state = Object.assign({}, this.state, newState);
            resolve(this.state);
        });
    }

    /**
     * Init state
     */
    initState() {
        const countOfItems = this.catalog.querySelectorAll(this.settings.itemSelector).length;
        let postsPerPage;
        let category = null;
        let pages = 1;

        // Is has postsPerPage attr -> set postsPerPage to request parameters
        if (this.catalog && this.catalog.hasAttribute(this.settings.postsPerPageAttr)){
            postsPerPage = parseInt(this.catalog.getAttribute(this.settings.postsPerPageAttr));
        } else {
            postsPerPage = countOfItems;
        }

        // Is has pages attr -> set pages to state
        if (this.catalog && this.catalog.hasAttribute(this.settings.maxNumPagesAttr)){
            pages = parseInt(this.catalog.getAttribute(this.settings.maxNumPagesAttr));
        }

        // Is has category attr -> set category to request parameters
        if (this.catalog && this.catalog.hasAttribute(this.settings.categoryAttr)){
            category = parseInt(this.catalog.getAttribute(this.settings.categoryAttr));
        }

        this.state = {
            isPending: false,
            isApiError: false,
            loaderType: null, // ['loadMore', 'filters']
            pages: pages,
            request: Object.assign({}, {
                paged: 1,
                posts_per_page: postsPerPage
            }, category ? { category: category } : {})
        }
    }
}

export default Catalog;