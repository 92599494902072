import ENV from './Env';
import dayjs from 'dayjs';

function phoneMask(){    
    var currentLang = document.querySelector('.js-current-lang-set').value;
    var phoneMask = '000-000-000';
    if( currentLang=='EN' ){
        phoneMask = '000-000-000';
    }
    return phoneMask;    
}

const MASK = {
    phone: {
        mask: phoneMask()
    },
    price: {
        mask: Number
    },
    zip: {
        mask: '00-000'
    },
    cardNumber: {
        mask: '0000 0000 0000 0000'
    },
    date: {
        mask: Date, 
        pattern: ENV.dateFormat,
        lazy: false,
        format: function (date) {
            return dayjs(date).format(ENV.dateFormat);
        },
        parse: function (str) {
            return dayjs(date).format(ENV.dateFormat);
        },
    
        blocks: {
            YYYY: {
                mask: IMask.MaskedRange,
                from: 1900,
                to: 2100
            },
            MM: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12
            },
            DD: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 31
            }
        }
    },
    positiveNumber: {
        mask: Number,
        min: 0
    }
};

export default MASK;