import { ajax } from "../Helpers/Ajax";
import ROUTING from "../Config/Routing";
import { onRequestError, onRequestSuccess } from "../Helpers/Request";
import { AddButtonLoader, RemoveButtonLoader } from "./Button";
import { getElementParent } from "../Helpers/Element";
import ENV from "../Config/Env";
import { scrollPage } from "../Helpers/Scroll";
import { BoxLoading } from "./Box";

class NeedHelp {
    constructor (BottonSelector = ".js--kids-need-help") {
        this.buttons = document.querySelectorAll(BottonSelector);
        this.items = [];

        if (this.buttons.length > 0){
            for (let button of this.buttons) {
                this.items.push(new NeedHelpItem(button));
            }
        }
    }
}

class NeedHelpItem {
    constructor (Button) {
        this.button = Button;
        this.parent = getElementParent(this.button, ENV.parentSectionSelector);
        this.isLoaded = false;

        if (this.parent) {
            this.responseContainer = document.createElement('div');
            this.responseContainer.className = 'section-heed-help js--kids-heed-help-container';
            this.events();
        }
    }

    events() {
        this.button.addEventListener('click', (event) => {
            event.preventDefault();
            this.getKids();
        });
    }

    getKids() {
        if (this.isLoaded) {
            scrollPage(this.responseContainer, 400, 'linear', -50);
            return;
        }

        AddButtonLoader(this.button);
        ajax({
            method: 'GET',
            url: ROUTING.get_kids_need_help
        }).then((response) => {
            if (response.html) {
                this.isLoaded = true;
                this.responseContainer.innerHTML = 
                    `<div class="container-fluid">
                        <div class="row persons-grid">
                            ${response.html}
                        </div>
                    </div>`;
                this.parent.appendChild(this.responseContainer);
                window.performanceLoad.researchItems();
                window.performanceLoad.runProcessing();
                scrollPage(this.responseContainer, 400, 'linear', -50);
            }
            RemoveButtonLoader(this.button);
            onRequestSuccess(response);
        }).catch((error) => onRequestError(error, this.button));
    }
}

export default NeedHelp;