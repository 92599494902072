const FPWD_APPLE_PAY = {
    merchantId: '',
    environment: '',
    buttonPaySelector: '.js--payment-button-applepay',
    totalLabel: '',
    countryCode: "PL",
    currencyCode: "PLN",
    merchantCapabilities: ['supports3DS'],
    supportedNetworks: ['visa', 'masterCard'],
    getSessionUrl: '/'
}

class FPWDApplePay {
    constructor (Config = {}) {
        this.config = Object.assign({}, FPWD_APPLE_PAY, Config);
        this.buttons = document.querySelectorAll(this.config.buttonPaySelector);
        this.dispatchHandlers = {};
        this.initState();
    }   

    getToken(totalPrice = null) {
        this.setTransactionInfo(totalPrice);

        this.paymentSession = new ApplePaySession(1, this.state.paymentDataRequest);
        this.paymentSession.onvalidatemerchant = (event) => {
            this.getApplePaySession(event.validationURL).then((response) => {
                this.paymentSession.completeMerchantValidation(response);
            }).catch((error) => {
                this.emit('error', { 
                    paymentService: this, 
                    error: { 
                        ...error, 
                        status: { 
                            code: 'FAIL_VALIDATE_MERCHANT' 
                        } 
                    } 
                });
            });
        }
        
        this.paymentSession.onpaymentauthorized = (event) => {
            this.emit('success', {
                paymentService: this,
                environment: this.config.environment,
                merchantId: this.config.merchantId, 
                data: { 
                    paymentData: event.payment,
                    token: JSON.stringify(event.payment.token.paymentData)
                }
            });
        }

        this.paymentSession.oncancel = (event) => {
            this.emit('error', { 
                paymentService: this,
                error: { 
                    ...event, 
                    status: { 
                        code: 'CANCELED' 
                    } 
                } 
            });
        }

        this.paymentSession.abort = (event) => {
            this.emit('error', { 
                paymentService: this,
                error: { 
                    ...event, 
                    status: { 
                        code: 'ABORTING'
                    } 
                } 
            });
        }
    
        this.paymentSession.begin();
    }

    setPaymentStatus(isSuccess = false) {
        this.paymentSession.completePayment(isSuccess ? ApplePaySession.STATUS_SUCCESS : ApplePaySession.STATUS_FAILURE);
    }

    getApplePaySession(validationURL) {
		return new Promise((resolve, reject) => {
			var xhr = new XMLHttpRequest();
			xhr.onload = function() {
				var data = JSON.parse(this.responseText);
				resolve(data);
			};
			xhr.onerror = reject;
            xhr.open('POST', this.config.getSessionUrl);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
			xhr.send(`validationURL=${validationURL}`);
		});
	}

    checkIfApplePayIsSupported() {
        const IS_SUPPORTED = window.ApplePaySession && ApplePaySession.canMakePayments;

        if (IS_SUPPORTED){
            const checkMerchantId = ApplePaySession.canMakePaymentsWithActiveCard(this.config.merchantId);
            checkMerchantId.then((canMakePayments) => {
                if (canMakePayments) {
                    this.showPaymentButtons();
                    return true;
                } else { 
                    this.hidePaymentButtons();
                    return false;
                }
            });
        } else {
            this.hidePaymentButtons();
            return false;
        }
    }

    /**
     * Show payment buttons -> if isReadyToPay result true
     */
    showPaymentButtons() {
        for (let button of this.buttons){
            button.classList.remove('hidden-payment-button');
        }
    }


    /**
     * Hide payment buttons -> if isReadyToPay result false or error
     */
    hidePaymentButtons() {
        for (let button of this.buttons){
            button.classList.add('hidden-payment-button');
        }
    }


    /**
     * Set transaktion info before generate token
     */
    setTransactionInfo(totalPrice) {
        let transactionInfo = {
            ...this.state.paymentDataRequest.total,
            amount: totalPrice.toString(),
        }

        this.setState({
            paymentDataRequest: Object.assign({}, this.state.paymentDataRequest,  { ...this.state.paymentDataRequest, total: transactionInfo })
        });
    }


    /**
     * INIT STATE
     */
    initState() {
        const PAYMENT_DATA_REQUEST = {
            countryCode: this.config.countryCode,
            currencyCode: this.config.currencyCode,
            merchantCapabilities: this.config.merchantCapabilities,
            supportedNetworks: this.config.supportedNetworks,
            total: {
              label: this.config.totalLabel,
              type: "final",
              amount: "0"
            }
        };

        this.state = {
            isSupported: this.checkIfApplePayIsSupported(),
            paymentDataRequest: PAYMENT_DATA_REQUEST
        };
    }

    /**
     * SET STATE
     * @param {Object} newState 
     */
    setState(newState) {
        this.state = Object.assign({}, this.state, newState);
    }

    // Listener
    on(eventName, callback) {
        let existsHandlerCollection = this.dispatchHandlers[eventName];
        if (existsHandlerCollection) {
            existsHandlerCollection.push(callback);
            this.dispatchHandlers[eventName] = existsHandlerCollection;
        } else {
            this.dispatchHandlers[eventName] = [callback];
        }
        return this;
    }

    // Emiter
    emit(eventName, data = {}) {
        let handlerCollections = this.dispatchHandlers[eventName];

        if (handlerCollections && handlerCollections.length) {
            handlerCollections.forEach(handler => {
                handler(data);
            });
        }
    }
}

export default FPWDApplePay;