export function getCookie(name) {
    let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

export function setCookie(name, value, expires = null, path = '/', domain = '', secure = false) {
    let newCookie = name + '=' + escape(value) + '; '
    let date;

    if (expires !== null) {
        date = new Date();
        date.setTime(date.getTime() + (expires * 24 * 60 * 60 * 1000));
        newCookie += 'expires=' + date.toGMTString() + '; ';
    }

    newCookie += 'path=' + path + '; ';
    newCookie += (domain !== "") ? 'domain=' + domain + '; ' : '';
    newCookie += (secure === true) ? 'secure;' : '';
    document.cookie = newCookie;
}

export function deleteCookie(name) { 
    setCookie(name, '', -1); 
}